import React, { useState, useEffect } from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import './grade.css';
import useTable from './useGradeTable';
import AddCommentOutlinedIcon from '@material-ui/icons/AddCommentOutlined';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Myswitch from './Myswitch';
import exportFromJSON from 'export-from-json';
import SearchComponentMember from './search';
import Notifications, { notify } from 'react-notify-toast';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
const { readCookie } = require('../Functions/Cookies.js');

const fields = {
  schoolname: 'School',
  board: 'board',
  branch: 'branch',
  city: 'City',
  state: 'State',
  students: 'Student',
  teachers: 'teachers',
  expirydate: 'expdate',
};

//labelling of data in table view on component render
const headCells = [
  { id: 1, label: 'S.No.' },
  { id: 2, label: 'Grade' },
  { id: 3, label: 'Sections' },
  { id: 4, label: 'Subjects' },
  { id: 5, label: 'Subscription' },
];

const exportCsv = () => {
  const schoolId = localStorage.getItem('schoolsId');
  fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/grades?schoolId=${schoolId}&export=true`, {
    headers: {
      token: `${readCookie('token')}`,
    },
  })
    .then((res) => res.json())
    .then((item) => {
      console.log("Export data==>",item);
      const data = item.data.grades.map(result=>({
        "Grade":result.grade,
        "Sections" :result.sections.map((secs)=>{
          console.log(secs);
            return secs.section
        }).join(),
        "Subjects":result.subjects.map((sub)=>{
              return sub.subject
        }).join()
      }));
      console.log("Exporting csv in grades==>",data);
      const fileName = 'GRADES';
      const exportType = exportFromJSON.types.csv;

      exportFromJSON({ data, fileName, exportType });
    });
};

function TeacherSchedule() {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [arrayHandler, setArrayHandler] = useState(['1']);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const notification = (data) => {
    let myColor = { background: '#5CE0D2', text: 'black' };
    notify.show(data, 'custom', 2000, myColor);
  };
  const handleRemove = () => {
    const newArray = arrayHandler;
    console.log(newArray);
    if (newArray.length >= 2) {
      newArray.pop();
      setArrayHandler([...newArray]);
    } else notification('atleast one persist');
  };

  const handleAdd = () => {
    const newArray = arrayHandler;
    if (newArray.length < 3) setArrayHandler([...arrayHandler, Math.random()]);
    else notification("We can't add more options Sorry Limits upto 3");
  };

  let subjectsArray = [];

  const [sectionOptions, setSectionOptions] = useState([]);
  //class Options
  const [classOptions, setClassOptions] = useState([]);
  //subject Options
  const [subjectOptions, setSubjectOptions] = useState([]);

  const [subectsRespectiveWithId, setSubjectsRespectiveId] = useState([]);
  //section apis //class apis  // subject apis
  //find id with subjects
  const findIdBySubjectName = (subject) => {
    console.log("Inside findIdBySubjectName function ================")
    console.log("Subject to find the Id -->",subject);
    console.log("All subjects w.r.t Id ==> ",subectsRespectiveWithId);
    for (var i = 0;i< subectsRespectiveWithId.length; i++) {
      if (subectsRespectiveWithId[i].name === subject){
        console.log("Found subject===> ",subectsRespectiveWithId[i])
        return subectsRespectiveWithId[i]._id;
      }
    }
  };

  const [fetched, setFetched] = useState(false);

  //To return the subjects of a selected subject
  const fetchSubjectId = async (classId) => {
    
    // console.log("Data in fetchSubjectId ======>",)
    
    //iterate classes
    // for (var i = 0; i < classLength; i++) {
    //   let classId = data[i]._id;

      console.log('selcted classId->', classId);
      try {
        await fetch(
          `${process.env.REACT_APP_ORIGIN}/api/v1/courses/${classId}`,
          {
            headers: {
              token: `${readCookie('token')}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log('Data for id ->', classId);
            console.log(data);
            let subjectWithId = data ? data.data.course.subjects : [];
            subjectsArray = subjectWithId;
            setSubjectsRespectiveId(subjectsArray);
          });
      } catch (e) {
        toast.error(e);
        console.log(e)
      // }
    }
  };

  const getSchoolInfo = async () => {
    const schoolId = localStorage.getItem('schoolsId');
    try {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/schools/${schoolId}`,
        {
          headers: {
            token: `${readCookie('token')}`,
          },
        }
      )
        .then((res) => res.json())
        .then((item) => {
          console.log(item, 'school info');
          setClassOptions(item.data.school.courses);
          let data = item.data.school.courses;
          let classLength = item.data.school.courses.length;

          // fetchsubjectId(data, classLength);
        });
    } catch (e) {
      toast.error(e);
    }
  };

  const getSectionInfo = async () => {
    try {
      await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/sections`, {
        headers: {
          token: `${readCookie('token')}`,
        },
      })
        .then((res) => res.json())
        .then((item) => {
          console.log('get section info res=>',item);
          // console.log(item, 'section info');
          const length = item.data.sections.length;
          const allOptions = [];
          for (var i = 0; i < length; i++) {
            const obj = {
              // value: item.data.sections[i]._id,
              value:item.data.sections[i].section,
              label: item.data.sections[i].section,
            };
            allOptions.push(obj);
          }
          setSectionOptions(allOptions);
          console.log('all Sections=>',allOptions);
        });
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    getSchoolInfo();
    getSectionInfo();
  }, []);

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: 'ellipsis',
      maxWidth: '90%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'initial',
    }),
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex((selected) => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ', ';
    const val = `${label},`;
    return val;
  };
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const handleSubject = async (e) => {
    const classId = e.target.value.split(',')[1];

    if (classId === 'Class') return;

    fetchSubjectId(classId);
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/courses/${classId}`, {
      headers: {
        token: `${readCookie('token')}`,
      },
    })
      .then((res) => res.json())
      .then((item) => {

        console.log("Response after selecting class->",item)

        const length = item.data?.course.subjects.length;
        const allOptions = [];

        for (var i = 0; i < length; i++) {
          const obj = {
            value: item.data.course.subjects[i]._id,
            label: item.data.course.subjects[i].name,
          };
          allOptions.push(obj);
        }
        setSubjectOptions(allOptions);
      });
  };

  //pushing after Assigning

  const [uploadData,setUploadData] = useState([]);
  const handleData =async () => {
    var ele = document.getElementsByClassName('select');

    console.log("Select elements array==>",ele);
    //Iterate over all the select elements
    let i=0;
    for await (let index of ele) {

      if(i===ele.length){
        break
      }

      var obj;

      //extract the id and name
      const courseName = ele[i].value.split(',')[0];
      const courseId = ele[i].value.split(',')[1];
      
      //Extract the sections and subjects selected
      const sections = ele[i + 1].innerText.split(',');
      const subjects = ele[i + 2].innerText.split(',');

      //Remove the Prototype field from the array
      sections.pop();
      subjects.pop();
      
      console.log("Selected subjects==>",subjects);
      console.log("Selected sections==>",sections);
      // console

      //create a new array containing the objects with proper format of subject name and id
      const newSubjects = subjects.map((item) => ({
          subjectId: findIdBySubjectName(item),
          subject: item,
      }));

      console.log("New subjects object after mapping ===>",newSubjects);
      console.log("CourseId to be sent ===>",courseId);
      console.log("CourseName to be sent ===>",courseName);
      console.log("sections to be sent ===>",sections);

      //create the object to be pushed to the array
      obj = {
        schoolId: localStorage.getItem('schoolsId'),
        courseId,
        courseName,
        sections,
        subjects: newSubjects,
      };
      // data.push(obj);
      console.log("Object to be pushed ===>",obj)
      // uploadData.push({
      //   schoolId: localStorage.getItem('schoolsId'),
      //   courseId:courseId,
      //   courseName:courseName,
      //   sections:sections,
      //   subjects: newSubjects,
      // })
      uploadData.push(obj);
      console.log("After pushing object, data array to be sent==>",uploadData)
      
      setUploadData([...uploadData,obj]);
      console.log("After updating state, data array to be sent==>",uploadData)

      i+=3;

    }

    // uploadData.shift();
    console.log("Data sent for assiggn class and subject==>",uploadData)
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/grades/`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        token: `${readCookie('token')}`,
      },
      body: JSON.stringify({
        schoolId: localStorage.getItem('schoolsId'),
        grades: uploadData,
      }),
    })
      .then((res) => res.json())
      .then(async(item) => {
        console.log("Add class and Assign Teacher==>",item);
        if (item.errorLogs.length === 0) {
          notification('success full created');
          handleClose();
          const schoolId = localStorage.getItem('schoolsId');
          await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/grades?schoolId=${schoolId}`,{
              headers: {
                token: `${readCookie('token')}`,
              },
            }
          )
            .then((res) => res.json())
            .then((item) => {
              setData(item.data.grades);
            });
        } else {
          notification(item.errorLogs[0]);
        }
      });
  };

  const handleChange = (select) => {
    console.log(select);
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(data, headCells); //user defined hooks

  //fetch table data
  useEffect(() => {
    const schoolId = localStorage.getItem('schoolsId');
    console.log(schoolId);
    fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/grades?schoolId=${schoolId}`,
      {
        headers: {
          token: `${readCookie('token')}`,
        },
      }
    )
      .then((res) => res.json())
      .then((item) => {
        setData(item.data.grades);
        setFetched(true);
      });
  }, []);

  return (
    <div className='teacher_schedule'>
      <div className='grade-upper'>
        <SearchComponentMember setData={setData}></SearchComponentMember>
      </div>

      <div className='teacher_schedule_table_header'>
        <div
          className='teacher_schedule_table_header_left'
          onClick={handleClickOpen}
        >
          <AddBoxOutlinedIcon></AddBoxOutlinedIcon> &nbsp; Assign Class &
          Subject
        </div>
        <div
          className='teacher_schedule_table_header_right'
          onClick={exportCsv}
        >
          <SystemUpdateAltOutlinedIcon></SystemUpdateAltOutlinedIcon> &nbsp;
          Export Csv
        </div>
      </div>

      {fetched ? (
        <TblContainer>
          <TblHead></TblHead>

          <TableBody>
            {data.map((item, index) => (
              <TableRow>
                <TableCell>
                  <b>{index + 1}</b>
                </TableCell>
                <TableCell>
                  <b style={{ textTransform: 'capitalize' }}>{item.grade}</b>
                </TableCell>

                <TableCell>
                  {item.sections.map((section) => (
                    <span style={{ textTransform: 'capitalize' }}>
                      <b> {section.section},</b>
                    </span>
                  ))}
                </TableCell>
                <TableCell>
                  {item.subjects.map((subject) => (
                    <span style={{ textTransform: 'capitalize' }}>
                      <b> {subject.subject},</b>
                    </span>
                  ))}
                </TableCell>

                <TableCell>
                  <Myswitch
                    enabled={item.enabled}
                    type={'grades'}
                    id={item._id}
                  ></Myswitch>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      ) : (
        <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
          <h3>Loading...</h3>
        </div>
      )}

      {/**assgin Classes */}
      <Dialog
        maxWidth={'650px'}
        height={'600px'}
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        style={{ padding: '10px!important' }}
      >
        <DialogContent>
          <div className='staff_modal_assign_class'>
            <div className='staff_modal_assign_class_heading'>
              <h2>Assign Classes & Subejcts</h2>
              <h2 onClick={handleClose}>X</h2>
            </div>
            <div className='staff_modal_assign_class_body'>
              {arrayHandler.map((item) => {
                return (
                  <>
                    <div className='staff_modal_assign_class_body_selectBox'>
                      <div className='linkAnother'>Link Classes & Subjects</div>
                      <div
                        className='addAnother'
                        onClick={() => {
                          handleAdd();
                        }}
                      >
                        Add Another
                      </div>
                      <div
                        className='removeAnother'
                        onClick={() => {
                          //logic of  remove elements
                          handleRemove();
                        }}
                      >
                        Remove Another
                      </div>
                      <div
                        className='staff_modal_assign_class_body_class'
                        style={{
                          zIndex: '1000000000',
                          width: '20%',
                          height: '30px',
                        }}
                      >
                        <select
                          style={{ height: '38px', marginTop: '0px' }}
                          className='select'
                          onChange={handleSubject}
                        >
                          <option> Class</option>
                          {classOptions.map((item) => <option value={`${item.name},${item._id}`}>{item.name}</option>)}
                        </select>
                      </div>
                      <div
                        className='staff_modal_assign_class_body_section'
                        style={{ width: '40%', fontSize: '10px!important' }}
                      >
                        <Select
                          className='select'
                          placeholder={'Select Section'}
                          options={sectionOptions}
                          isMulti
                          components={{
                            MultiValueContainer: multiValueContainer,
                            // Option: CustomOption,
                          }}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={true}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      </div>
                      <div
                        className='staff_modal_assign_class_body_subject'
                        style={{ width: '50%' }}
                      >
                        <Select
                          className='select'
                          isMulti={true}
                          onChange={handleChange}
                          components={{
                            MultiValueContainer: multiValueContainer,
                            // Option: CustomOption,
                          }}
                          options={subjectOptions}
                          placeholder={'Select Subject'}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={true}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </>
                );
              })}

              <div className='modal_button'>
                <button
                  variant='outlined'
                  color='success'
                  onClick={handleClose}
                  color='primary'
                >
                  Cancel
                </button>
                <button onClick={handleData} color='primary'>
                  Add
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Notifications options={{ zIndex: 200, top: '50px' }} />
    </div>
  );
}

export default TeacherSchedule;
