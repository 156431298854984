import React, { useState, useEffect } from 'react';
import './teacher.css';
import useTable from './useTableTeacher';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import Myswitch from './Myswitch';
import exportFromJSON from 'export-from-json';
import { useHistory } from 'react-router-dom';
import FilterListIcon from '@material-ui/icons/FilterList';
import Search from './Search';
import MemberModal from '../AllModal/MemberModal';
import AddTeacher from '../AllModal/AddTeacher';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';
import CSVtoJSON from '../Functions/CSVtoJSON';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { ValidateStaff } = require('../Functions/Validation.js');
const { readCookie } = require('../Functions/Cookies.js');

// const fields = {
//   schoolname: 'School',
//   board: 'board',
//   branch: 'branch',
//   city: 'City',
//   state: 'State',
//   students: 'Student',
//   teachers: 'teachers',
//   expirydate: 'expdate',
// };

//labelling of data in table view on component render
const headCells = [
  { id: 1, label: '  Name' },
  { id: 2, label: 'Role' },
  { id: 3, label: ' Email' },

  { id: 4, label: ' Contact ' },
  { id: 5, label: 'Signup Date ' },
  { id: 6, label: ' Expiry Date' },
];

function Teacher() {
  const [
    crossStatus,
    //  setCrossStatus
  ] = useState('No');
  // const filename = 'csvfile'; //hooks for csv files generating
  // const { saveAsCsv } = useJsonToCsv(); //save csv file  from json
  // const [viewType, setViewType] = useState('card');
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [fetched, setFetched] = useState(false);

  //Filter Teacher
  const addFilter = () => {
    var modal = document.getElementsByClassName('filterMember')[0];
    modal.style.top = '50%';
  };

  const addTeacher = () => {
    var modal = document.getElementsByClassName('addTeacher')[0];
    modal.style.top = '50%';
  };

  //Sidebar Shrink
  // const sideBarShrink = () => {
  //   var sideBar = document.getElementsByClassName('Sidebar')[0];
  //   var menuName = document.getElementsByClassName('menu-Name');
  //   var logout = document.getElementsByClassName('logout')[0];
  //   var aboveDiv = document.getElementsByClassName('Above-div')[0];
  //   var lowerDiv = document.getElementsByClassName('Lower-div')[0];
  //   lowerDiv.style.marginTop = '150px';
  //   aboveDiv.style.display = 'none';
  //   logout.style.display = 'none';
  //   sideBar.style.flex = '0.1';

  //   for (var i = 0; i < menuName.length; i++) {
  //     menuName[i].style.display = 'none';
  //   }
  // };
  const [data, setData] = useState([]);
  const [length, setLength] = useState(0);
  console.log(filteredData, 44);
  useEffect(() => {
    const schoolsId = window.localStorage.getItem('schoolsId');

    fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/staffs?school=${schoolsId}&limit=10`,
      {
        headers: {
          token: `${readCookie('token')}`,
        },
      }
    )
      .then((data) => data.json())
      .then(async (res) => {
        console.log(res,'staff');
        localStorage.removeItem('dataType');
        localStorage.removeItem('signed');
        localStorage.removeItem('sortBy');

        await fetch(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/total?school=${schoolsId}`,
          {
            headers: {
              token: `${readCookie('token')}`,
            },
          }
        )
          .then((data) => data.json())
          .then((res) => {
            setLength(res.data.totalStaffs);
            console.log(res);
          });
        console.log(res.data);
        setData(res.data ? res.data.staffs : []);
        setFetched(true);
      });
  }, []);

  const { TblContainer, TblHead, TblPagination } = useTable(
    setData,
    headCells,
    length,
    page,
    setPage
  ); //user defined hooks

  const exportCsv = () => {
    const schoolsId = window.localStorage.getItem('schoolsId');

    fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/staffs?school=${schoolsId}&fields=name,email,contact,signUpDate,expiryDate,role&export=true`,
      {
        headers: {
          token: `${readCookie('token')}`,
        },
      }
    )
      .then((res) => res.json())
      .then((staffsData) => {
        console.log(staffsData);
           
        const data = staffsData.data.staffs.map(item=>({
          "School Name":item.school.name,
          "Name":item.name,
          "Branch":item.school.branch,
          "Role":item.role,
          "Email":item.email,
          "Contact":item.contact,
          "SignUp Date":new Date(item.signUpDate).toLocaleDateString(),
          "Expiry Date":new Date(item.expiryDate).toLocaleDateString()
        }));;
        const fileName = 'Teacher';
        const exportType = exportFromJSON.types.csv;

        exportFromJSON({ data, fileName, exportType });
      });
  };

  //sort by function
  const handleSort = (DataType, SortBy) => {
    const schoolsId = window.localStorage.getItem('schoolsId');

    let signed = '';
    if (SortBy === 'desc') {
      signed = '-';
    }

    //set data in localStorage
    localStorage.setItem('dataType', DataType);
    localStorage.setItem('signed', signed);
    localStorage.setItem('sortBy', SortBy);

    fetch(
      `${
        process.env.REACT_APP_ORIGIN
      }/api/v1/staffs?school=${schoolsId}&sort=${signed}${DataType}&page=${
        page + 1
      }&limit=10`,
      {
        headers: {
          token: `${readCookie('token')}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setFilteredData([]);
        setData(data.data.staffs);
      });
  };

  //Function to upload converted csv file
  const uploadCSV = async (e) => {
    if (!e.target.files.length) {
      toast.error('File is empty', { autoClose: false });
      return;
    }

    //To upload csv-to-json file to the server
    const reader = new FileReader();

    // function read() {

    //Extract contents of the CSV file
    const csv = e.target.files[0];
    reader.readAsText(csv);

    reader.onload = function (e) {
      let emailList = [];
      let contactList = [];

      //Convert CSV content to JSON
      const jsonContent = CSVtoJSON(e.target.result, emailList, contactList);

      if (jsonContent.length > 1000) {
        toast.error('Only 1000 errors allowed per file', { autoClose: false });
        return;
      }

      //Validate content of JSON
      let validated = false,
        allCorrect = true;
      jsonContent.splice(-1);
      // jsonContent.forEach((staff, index) => {
      //   validated = ValidateStaff(staff, emailList);
      //   console.log(index, validated);
      //   if (!validated) {
      //     allCorrect = false;
      //     toast.error(`Error at entry ${index + 1}`, { autoClose: false });
      //     return;
      //   }
      // });

      if (emailList.length !== new Set(emailList).size) {
        toast.error('Duplicate emails found', { autoClose: false });
        allCorrect = false;
        return;
      }

      // if (contactList.length !== new Set(contactList).size) {
      //     allCorrect = false
      // }

      //Proceed only if all are correct
      if (allCorrect) {
        const schoolId = window.localStorage.getItem('schoolsId');
        const docs = jsonContent;

        try {
          fetch(
            `${process.env.REACT_APP_ORIGIN}/api/v1/schools/${schoolId}/import-v2?user=teacher`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                token: `${readCookie('token')}`,
              },
              body: JSON.stringify({ docs }),
            }
          )
            .then((response) => response.json())
            .then((response) => {
              console.log(response);

              if (
                response.status === 'error' ||
                response.status === 'fail' ||
                !response.status
              ) {
                toast.error(response.message, { autoClose: false });
                document.getElementById('teacher-file-input').value = '';
                // window.location.reload()
                return;
              } else {
                toast.success(
                  'Teachers imported successfully! Kindly refresh to see updates',
                  { autoClose: false }
                );
                document.getElementById('teacher-file-input').value = '';
                const log = response?.errorLogs;
                if (log) {
                  exportFromJSON({
                    data: log,
                    fileName: 'TEACHER_EXPORT_LOGS',
                    exportType: exportFromJSON.types.csv,
                  });
                }
                if (response.errors) {
                  response.errorLogs.forEach((item) => {
                    toast.error(item, { autoClose: false });
                  });
                }
                // window.location.reload()
              }
            });
        } catch (e) {
          toast.error(e.message, { autoClose: false });
          console.log(e);
        }
      } else {
        toast.error('Error in csv', { autoClose: false });
      }
    };
  };

  return (
    <div className='teacher'>
      <MemberModal setData={setFilteredData} page={page} />
      <AddTeacher setData={setData} setLength={setLength}></AddTeacher>
      <div className='teacher_upper_body'>
        <Search
          searchStatus='Yes'
          TblPagination={TblPagination}
          setData={setData}
          setLength={setLength}
          school={'TEACHER'}
          paginationCheck={'Yes'}
          crossStatus={crossStatus}
        ></Search>
      </div>
      <div className='teacher_lower_body'>
        <div className='Teacher_MainBody_RIGHT'>
          {/**Teacher mid*/}
          <div className='Teacher_MainBody_Right_mid'>
            <div
              className='Teacher_MainBody_Right_mid_left'
              onClick={() => {
                addTeacher();
              }}
              style={{ cursor: 'pointer' }}
            >
              <AddBoxOutlinedIcon className='item'></AddBoxOutlinedIcon> Add
              Teacher
            </div>
            <div
              className='student_MainBody_Right_mid_left'
              style={{
                cursor: 'pointer',
                marginRight: 'auto',
                paddingLeft: '10px',
              }}
              onClick={() => {}}
            >
              <PlaylistAddOutlinedIcon className='item'></PlaylistAddOutlinedIcon>{' '}
              Import Teachers
              <input
                type='file'
                className='teacher-file-input'
                id='teacher-file-input'
                accept='.csv'
                onChange={(e) => {
                  uploadCSV(e);
                }}
              />
            </div>
            <div className='Teacher_MainBody_Right_mid_right'>
              <div className='item-1 item' onClick={exportCsv}>
                <SystemUpdateAltOutlinedIcon className='Teacher_MainBody_Right_mid_right_icon'></SystemUpdateAltOutlinedIcon>{' '}
                Export Csv
              </div>
              <div className='item-2 item'>
                <Menu
                  menuButton={
                    <MenuButton>
                      <ImportExportIcon className='student_MainBody_Right_mid_right_icon'></ImportExportIcon>{' '}
                      Sort By
                    </MenuButton>
                  }
                >
                  <SubMenu label='Name'>
                    <MenuItem
                      onClick={() => {
                        handleSort('name', 'asc');
                      }}
                    >
                      Ascending
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleSort('name', 'desc');
                      }}
                    >
                      Descending
                    </MenuItem>
                  </SubMenu>
                  <SubMenu label='SignUpDate'>
                    <MenuItem
                      onClick={() => {
                        handleSort('signUpDate', 'asc');
                      }}
                    >
                      Ascending
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleSort('signUpDate', 'desc');
                      }}
                    >
                      Descending
                    </MenuItem>{' '}
                  </SubMenu>
                  <SubMenu label='ExpiryDate'>
                    <MenuItem
                      onClick={() => {
                        handleSort('expiryDate', 'asc');
                      }}
                    >
                      Ascending
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleSort('expiryDate', 'desc');
                      }}
                    >
                      Descending
                    </MenuItem>
                  </SubMenu>
                </Menu>
              </div>
              <div
                className='item-3 item'
                onClick={() => {
                  addFilter();
                }}
              >
                <FilterListIcon> </FilterListIcon> &nbsp;Filter
              </div>
            </div>
          </div>
          {/**Teacher right cards or table view*/}
          {fetched ? (
            <TblContainer>
              <TblHead></TblHead>
              <TableBody>
                {(filteredData.length ? filteredData : data).map((item) => {
                  var expiryDate = item.expiryDate.split('T');
                  var signUpDate = item.signUpDate.split('T');
                  var name =
                    item.name.charAt(0).toUpperCase() + item.name.slice(1);
                  var role =
                    item.role.charAt(0).toUpperCase() + item.role.slice(1);
                  return (
                    <>
                      <TableRow key={item.name}>
                        <TableCell
                          key={name}
                          className='name'
                          onClick={() => {
                            localStorage.setItem('staffId', item._id);
                            localStorage.setItem('teacherName', name);
                            history.push('/dashboard/teacher');
                          }}
                        >
                          {name}
                        </TableCell>
                        <TableCell
                          key={role}
                          onClick={() => {
                            localStorage.setItem('staffId', item._id);
                            localStorage.setItem('teacherName', name);
                            history.push('/dashboard/teacher');
                          }}
                        >
                          {role}
                        </TableCell>
                        <TableCell
                          key={item.email}
                          onClick={() => {
                            localStorage.setItem('staffId', item._id);
                            localStorage.setItem('teacherName', name);
                            history.push('/dashboard/teacher');
                          }}
                        >
                          {item.email}
                        </TableCell>
                        <TableCell
                          key={item.contact}
                          onClick={() => {
                            localStorage.setItem('staffId', item._id);
                            localStorage.setItem('teacherName', name);
                            history.push('/dashboard/teacher');
                          }}
                        >
                          {item.contact}
                        </TableCell>
                        <TableCell
                          key={signUpDate[0]}
                          onClick={() => {
                            localStorage.setItem('staffId', item._id);
                            localStorage.setItem('teacherName', name);
                            history.push('/dashboard/teacher');
                          }}
                        >
                          {signUpDate[0]}
                        </TableCell>

                        <TableCell
                          key={expiryDate[0]}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '80%',
                            justifyContent: 'space-between',
                          }}
                        >
                          {expiryDate[0]} &emsp;{' '}
                          <Myswitch enabled={item.enabled}></Myswitch>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </TblContainer>
          ) : (
            <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
              <h3>Loading...</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Teacher;
