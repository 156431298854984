const { createCookie, readCookie } = require('./Cookies.js');

const ValidateStaff = (staff, emailList) => {
  // Validate name
  if (!staff.name || staff.name.length <= 2 || staff.name.length >= 31) {
    return false;
  }

  //Validate email
  if (!staff.email) {
    return false;
  }

  // Validate contact
  if (!staff.contact) {
    return false;
  }

  //Validate password
  if (!staff.password) {
    return false;
  }

  //Validate role
  const roles = ['principal', 'hod', 'teacher'];
  if (!staff.role || /[A-Z]/.test(staff.role) || !roles.includes(staff.role)) {
    return false;
  }

  //Validate expiry Date
  if (!staff.expiryDate) {
    return false;
  }

  return true;
};

const ValidateStudent = (student, emailList) => {
  // Validate name
  if (!student.name || student.name.length <= 2 || student.name.length >= 31) {
    return false;
  }

  //Validate email
  if (!student.email) {
    return false;
  }

  // Validate contact
  if (!student.contact) {
    return false;
  }

  //Validate password
  if (!student.password) {
    return false;
  }

  //Validate expiry Date
  if (!student.expiryDate) {
    return false;
  }

  return true;
};

const ValidateToken = async () => {
  let valid = false;

  const token = readCookie('token');

  await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/auth/validate/${token}`)
    .then((response) => response.json())
    .then((response) => {
      if (response.status == 'authorized') {
        valid = true;
        window.localStorage.setItem('logged_in', true);
      }
    });

  return valid;
};

export { ValidateStaff, ValidateStudent, ValidateToken };
