import React, { useState, useEffect } from 'react';
import './dashboard.css';
import ProfileImage from '../ProfileImage/ProfileImage';
import PropTypes from 'prop-types';
import user from '../user-circle.svg';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import useTable from './useTableDashboard';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import CachedIcon from '@material-ui/icons/Cached';
// import TablePagination from "@material-ui/core/TablePagination";
// import TableRow from "@material-ui/core/TableRow";
import exportFromJSON from 'export-from-json';
import PageVisitFilter from '../AllModal/PageVisitFilter';
import { makeStyles, useTheme } from '@material-ui/core/styles';
const { readCookie } = require('../Functions/Cookies.js');
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 'auto',
  },
});

const fillMissingData = (from, hour, data) => {
  const newdata = [];
  for (let i = 0; i < hour + 1; i++) {
    const hour = new Date(from + i * 60 * 60 * 1000).getHours();
    const dataIndex = data.findIndex((dt) => dt._id === hour);
    newdata.push(dataIndex > -1 ? data[dataIndex] : { _id: hour, users: 0 });
  }
  return newdata;
};

function Dashboard() {
  //States for the info on top of dashboard
  const [schoolName, setSchoolName] = useState('Loading...');
  const [imageUrl, setImageUrl] = useState(null);
  const date = new Date();

  //states for line chart and page visit
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartLabel, setLineChartLabel] = useState([]);
  const [pageVisitData, setPageVisitData] = useState([]);
  const [teachersList, setTeachersList] = useState([]);
  const [hodList, setHodList] = useState([]);
  const [pages, setPages] = useState(0);

  const last12Hours = new Date(Date.now() - 12 * 60 * 60 * 1000).toISOString();

  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, pageVisitData.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //data and options for line charts
  const datas = {
    labels: lineChartLabel,

    datasets: [
      {
        label: 'User Activity',
        data: lineChartData,
        fill: false,
        backgroundColor: '#5ce0d2',
        borderColor: '#5CE0D2',
      },
    ],
  };

  //labelling of data in table view on component render
  const headCells = [
    { id: 1, label: 'Name.' },
    { id: 2, label: 'Role' },
    { id: 3, label: 'Grade' },
    { id: 4, label: 'Chapter' },
    { id: 5, label: 'Topic' },
    { id: 6, label: 'Module' },
    { id: 7, label: 'Visit On' },
  ];

  const [length, setLength] = useState(0);
  const [data, setData] = useState([]);

  const { TblHead } = useTable(data, length, setData, headCells); //user defined hooks

  //Function used to get the image and school name  on the dashboard
  const getProfileDetails = async () => {
    const userId = window.localStorage.getItem('userId');
    try {
      await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}`, {
        headers: {
          token: `${readCookie('token')}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log('akkadabkkad  PROFILE====>', response.data.staff);

          setSchoolName(response.data.staff.school.name);
          setImageUrl(response.data.staff.profile.fileUrl);
          window.localStorage.setItem(
            'imageUrl',
            response.data.staff.profile.fileUrl
          );
        });
    } catch {}
  };

  //Get data for user activity chart
  const getActivityDetails = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/last12houractivemembers`,
        {
          headers: {
            token: `${readCookie('token')}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response);

          const graphData = fillMissingData(
            response.data.from,
            response.data.hours,
            response.data.userActivities
          );

          //Extracting number of users from response and filling missing labels
          let lineChartArr = [],
            labelsArr = [];

          for (let item of graphData) {
            lineChartArr.push(item.users);
          }
          for (let item of graphData) {
            labelsArr.push(item._id);
          }

          console.log('labels:', labelsArr);
          console.log('lineChart:', lineChartArr);

          setLineChartData(lineChartArr);
          setLineChartLabel(labelsArr);
        });
    } catch {}
  };

  //Function to get the page visit data

  const getPageVisitData = async (url, teacherName, hodName) => {
    try {
      if (teacherName === 'Selected') teacherName = undefined;
      if (hodName === 'Selected') hodName = undefined;
      url = url
        ? url
        : `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/pagevisitbyschoolmembers?visitOn[gte]=${last12Hours}`;
      await fetch(url, {
        headers: {
          token: `${readCookie('token')}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response, 'this is the filtered response');
          if (hodName !== undefined) {
            const res = response.data.pageVisits.filter(
              (r) => r?.user?.name === hodName
            );
            setPageVisitData(res);
          } else if (teacherName !== undefined) {
            const res = response.data.pageVisits.filter(
              (r) => r?.user?.name === teacherName
            );
            setPageVisitData(res);
          } else setPageVisitData(response.data.pageVisits);
        });
    } catch {}
  };

  useEffect(() => {
    const schoolsId = window.localStorage.getItem('schoolsId');
    console.log(schoolsId);
    fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/fetchAllTeachers?school=${schoolsId}`,
      {
        headers: {
          token: `${readCookie('token')}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        const t = response.data.staffs.filter((s) => s.role === 'teacher');
        console.log(t, 'this is the teachers list');
        const h = response.data.staffs.filter((s) => s.role === 'hod');
        setTeachersList(t);
        setHodList(h);
      });
  }, []);

  useEffect(() => {
    try {
      getProfileDetails().then((response) => {
        getPageVisitData().then((response) => {
          getActivityDetails().then((response) => {});
        });
      });
      // getProfileDetails()
      // getPageVisitData()
      // getActivityDetails()
    } catch {}
  }, []);

  //Function to fix the date in pag e visit section
  const fixVisitOn = (value) => {
    // 2021-08-11T01:17:33.799Z <- input value

    const date = new Date(value);
    return date.toDateString() + ',' + date.toTimeString().split(' ')[0];
  };

  //Function to export the csv file for user acivity

  const setExportFunction = async (id) => {
    const t1 = ' A.M';
    const t2 = ' P.M';
    let t;
    if (id === 0) {
      t = '12' + t1;
    } else if (id === 12) {
      t = '12' + t2;
    } else if (id < 12) {
      t = id + t1;
    } else if (id > 12) {
      t = id - 12 + t2;
    }
    return t;
  };

  const exportUserActivityCSV = () => {
    fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/last12houractivemembers?export=true`,
      {
        headers: {
          token: `${readCookie('token')}`,
        },
      }
    )
      .then((response) => response.json())
      .then(async (response) => {
        console.log('Raw data for CSV=====>', response.data.userActivities);
        if (!response?.data?.userActivities) return;

        const modifiedDataPromises = response.data.userActivities.map(
          async (userActivity) => ({
            TIME: await setExportFunction(userActivity._id),
            USERS: userActivity.users,
          })
        );
        const modifiedData = await Promise.all(modifiedDataPromises);

        const modifiedDataValues = modifiedData.map((item) => ({
          TIME: item.TIME, // item.TIME now contains the resolved value
          USERS: item.USERS,
        }));

        exportFromJSON({
          data: modifiedDataValues,
          fileName: 'USER_ACTIVITY',
          exportType: exportFromJSON.types.csv,
        });
      });
  };

  //Function to export the csv file for page visits
  const exportPageVisitsCSV = () => {
    // fetch(
    //   `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/pagevisitbyschoolmembers?limit=50`,
    //   {
    //     headers: {
    //       token: `${readCookie('token')}`,
    //     },
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((response) => {
    //     console.log('Raw data for CSV======>', response.data.pageVisits);
    //     const formattedData = response.data.pageVisits.map((item) => ({
    //       User_Email: item.user?.email,
    //       User_Name: item.user?.name,
    //       User_Role: item.user?.role,
    //       Section: item.section,
    //       Subject: item.subject,
    //       Chapter: decodeURIComponent(item.chapter),
    //       Topic: decodeURIComponent(item.topic),
    //       Module: item.module,
    //       'Visited On': new Date(item.visitOn).toLocaleDateString(),
    //     }));
    //     const fileName = 'PAGE_VISIT_DATA';
    //     const exportType = exportFromJSON.types.csv;
    //     console.log('Raw data for CSV======>', formattedData);

    //     exportFromJSON({ data: formattedData, fileName, exportType });
    //   });
    console.log('Raw data for CSV======>', pageVisitData);
    const formattedData = pageVisitData?.map((item) => ({
      User_Email: item.user?.email,
      User_Name: item.user?.name,
      User_Role: item.user?.role,
      Section: item.section,
      Subject: item.subject,
      Chapter: decodeURIComponent(item.chapter),
      Topic: decodeURIComponent(item.topic),
      Module: decodeURIComponent(item.module),
      'Visited On': new Date(item.visitOn).toLocaleDateString(),
    }));
    const fileName = 'PAGE_VISIT_DATA';
    console.log('Raw data for CSV======>', formattedData);
    const csvContent = [
      Object.keys(formattedData[0]).join(','),
      ...formattedData.map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`)
          .join(',')
      ),
    ].join('\n');
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvContent], {
      type: 'text/csv;charset=utf-8;',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  const [isOpen, setIsOpen] = useState(false);

  const renderModal = () => {
    document.querySelector('.page_visit_filter').style.top = '0%';
  };

  return (
    <div className='dashboard'>
      <div className='dashboard-upper-part'>
        <div className='dashboard-upper-part-left'>
          <h2>{schoolName}</h2>
          <p>{date.toDateString()}</p>
        </div>
        <div className='dashboard-upper-part-right'>
          <Link to='/profile/edit'>
            <img
              src={imageUrl || user}
              id='image'
            ></img>
          </Link>
        </div>
      </div>
      <div className='dashboard-mid-part'>
        <div className='dashboard-mid-part-upper'>
          <div className='dashboard-mid-part-upper-left'>
            <div className='dashboard-mid-part-upper-left-1'>
              User Activity&nbsp;{' '}
              <CachedIcon
                onClick={getActivityDetails}
                style={{ cursor: 'pointer' }}
              ></CachedIcon>
            </div>
            <p>Active Users in last 12 hours</p>
          </div>
          <div
            className='dashboard-mid-part-upper-right'
            onClick={() => {
              exportUserActivityCSV();
            }}
          >
            <SystemUpdateAltOutlinedIcon
              style={{ marginTop: '4px' }}
            ></SystemUpdateAltOutlinedIcon>{' '}
            &nbsp;Export csv
          </div>
        </div>
        <div className='dashboard-mid-part-lower'>
          <Line
            data={datas}
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'TIME (Nth Hour of the DAY)',
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: 'USERS',
                  },
                },
              },
              plugins: {
                legend: {
                  labels: {
                    font: { family: 'Poppins' },
                  },
                },
              },
            }}
            width={300}
            height={280}
          />
        </div>
      </div>
      <div className='dashboard-lower-part'>
        <div className='dashboard-lower-part-heading'>
          <div className='dashboard-lower-part-heading-left'>
            <h3 style={{ marginLeft: '15px' }}>Page Visits</h3>
          </div>
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          > */}
          <TablePagination
            rowsPerPageOptions={[10, 25, 30, { label: 'All', value: -1 }]}
            colSpan={10}
            count={pageVisitData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          {/* <h5>Page</h5>{' '}
            <span
              className='arrow_style'
              title='previous page'
              onClick={() => changePage((pVal) => (pVal > 1 ? pVal - 1 : pVal))}
            >
              &#8592;
            </span>{' '}
            <h4>{page}</h4>{' '}
            <span
              className='arrow_style'
              title='next page'
              onClick={() => changePage((pVal) => pVal + 1)}
            >
              &#8594;
            </span> */}
          {/* </div> */}
          <div className='dashboard-lower-part-heading-right'>
            <div
              className='dashboard-lower-part-heading-right-icon1'
              onClick={() => {
                exportPageVisitsCSV();
              }}
            >
              <SystemUpdateAltOutlinedIcon
                style={{ marginTop: '4px' }}
              ></SystemUpdateAltOutlinedIcon>{' '}
              &nbsp;Export csv
            </div>
            <div
              className='dashboard-lower-part-heading-right-icon2'
              onClick={() => {
                renderModal();
              }}
            >
              <FilterListIcon style={{ marginTop: '4px' }}></FilterListIcon>{' '}
              &nbsp;Filter
            </div>
          </div>
          <PageVisitFilter
            getPageVisitData={getPageVisitData}
            page={pages}
            teachersList={teachersList}
            hodList={hodList}
          ></PageVisitFilter>
        </div>
        <div className='dashboard-lower-part-body'>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label='Report Card table'
            >
              <TblHead></TblHead>
              <TableBody>
                {/* <TableRow >
                                <TableCell>schoolname</TableCell>
                                <TableCell>board</TableCell>
                                <TableCell>Branch</TableCell>
                                <TableCell>Branch</TableCell>
                                <TableCell>Branch</TableCell>
                                <TableCell>Branch</TableCell>
                                <TableCell>Branch</TableCell>
                            </TableRow> */}

                {(rowsPerPage > 0
                  ? pageVisitData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : pageVisitData
                ).map((item) => (
                  <TableRow>
                    <TableCell>{item.user.name}</TableCell>
                    <TableCell>{item.user.role}</TableCell>
                    <TableCell>{item.section}</TableCell>
                    <TableCell>{item.chapter}</TableCell>
                    <TableCell>{item.topic}</TableCell>
                    <TableCell>{item.module}</TableCell>
                    <TableCell>{fixVisitOn(item.visitOn)}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
