import React, { useState, useEffect } from 'react';
import './PageVisitFilter.css';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { readCookie } = require('../Functions/Cookies.js');

function PageVisitFilter({ getPageVisitData, page, teachersList, hodList }) {
  let teachers = [];
  let hod = [];

  teachersList?.forEach((t) => {
    teachers.push(`${t.name}`);
  });

  hodList?.forEach((h) => {
    hod.push(`${h.name}`);
  });

  const closeModal = () => {
    document.querySelector('.page_visit_filter').style.top = '-500%';
  };

  const [data, setData] = useState({
    role: undefined,
    class: undefined,
    visitedOn: undefined,
    visitedTo: undefined,
    teacherName: undefined,
    hodName: undefined,
  });

  const handleRoleChange = (e) => {
    setData({
      ...data,
      teacherName: undefined,
      hodName: undefined,
      role: e.target.value,
    });
  };

  const [classes, setClasses] = useState([]);

  const getFilteredData = () => {
    let {
      role,
      class: Class,
      visitedOn,
      visitedTo,
      teacherName,
      hodName,
    } = data;
    if (role || Class || visitedOn || visitedTo) {
      visitedTo = visitedTo
        ? new Date(
            new Date(visitedTo).getTime() + 24 * 60 * 60 * 1000
          ).toISOString()
        : new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString();
      const url = visitedOn
        ? `${
            process.env.REACT_APP_ORIGIN
          }/api/v1/analytics/pagevisitbyschoolmembers?visitOn[lte]=${visitedTo}&visitOn[gte]=${new Date(
            visitedOn
          ).toISOString()}&section=${Class}&role=${role}&page=${page}`
        : `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/pagevisitbyschoolmembers?visitOn[lte]=${visitedTo}&section=${Class}&role=${role}&page=${page}`;
      getPageVisitData(url, teacherName, hodName);
    }
  };

  useEffect(() => {
    // getFilteredData();

    const getData = async () => {
      try {
        if (!data.class) {
          const id = localStorage.getItem('schoolsId');
          const url = `${process.env.REACT_APP_ORIGIN}/api/v1/grades?schoolId=${id}`;
          const response = await fetch(url, {
            headers: {
              token: readCookie('token'),
            },
          });
          const data = await response.json();
          let classes = [];
          data.data?.grades.forEach((e) => {
            e.sections.forEach((s) => classes.push(`${e.grade}-${s.section}`));
          });
          setClasses([...classes]);
        }
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      }
    };
    getData();
    // eslint-disable-next-line
  }, [page]);

  return (
    <div className='page_visit_filter'>
      <div className='filter_name_section'>
        <p>Filters</p>
        <button
          type='button'
          className=''
          onClick={closeModal}
        >
          <CloseOutlinedIcon />
        </button>
      </div>

      <div className='page_visit_filter_input_section'>
        <span>
          <div>
            <label htmlFor='filter_visitOn_input'>From Date</label>
            <br></br>
            <input
              type='date'
              name='filter_visitOn_input'
              id='filter_visitOn_input'
              onChange={(e) =>
                setData({
                  ...data,
                  visitedOn: e.target.value,
                })
              }
              value={data.visitedOn ? data.visitedOn : ''}
            />
          </div>
          <div>
            <label htmlFor='filter_visitTo_input'>To Date</label>
            <br></br>
            <input
              type='date'
              name='filter_visitOn_input'
              onChange={(e) =>
                setData({
                  ...data,
                  visitedTo: e.target.value,
                })
              }
              value={data.visitedTo ? data.visitedTo : ''}
            />
          </div>
        </span>
        <span>
          {/* Scond row left  */}
          <span>
            <label htmlFor='filter-role-input'>Role</label>
            <select
              name='filter-role-input'
              id='filter-role-input'
              onChange={(e) => {
                console.log(e.target.value, 'here');
                handleRoleChange(e);
                console.log(data.teacherName);
              }}
              value={data.role ? data.role : ''}
            >
              <option value={undefined}>Select</option>
              <option value='hod'>HOD</option>
              <option value='principal'>Principal</option>
              <option value='teacher'>Teacher</option>
              <option value='student'>Student</option>
            </select>
          </span>

          {/* Second row right  */}
          <span>
            <label htmlFor='filter_class_input'>Grade</label>
            <select
              name='filter_class_input'
              id='filter_class_input'
              onChange={(e) =>
                setData({
                  ...data,
                  class: e.target.value,
                })
              }
              value={data.class ? data.class : ''}
            >
              <option value={undefined}>Select</option>
              {classes.length > 0 &&
                classes.map((c) => (
                  <option
                    value={c}
                    key={c}
                  >
                    {c}
                  </option>
                ))}
            </select>
          </span>
        </span>
        {/* Third row  */}
        <span>
          {data?.role === 'teacher' && (
            <div className='page_visit_filter_teacher_input_section'>
              <label htmlFor='teacher_name_input'>Teacher's Name</label>
              <select
                name='teacher_name_input'
                id='teacher_name_input'
                onChange={(e) => {
                  setData({ ...data, teacherName: e.target.value });
                }}
                value={data.teacherName ? data.teacherName : undefined}
              >
                <option value={undefined}>Select</option>
                {teachers.length > 0 &&
                  teachers.map((c, index) => (
                    <option
                      value={c}
                      key={index}
                    >
                      {c}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </span>
        <span>
          {data?.role === 'hod' && (
            <div className='page_visit_filter_teacher_input_section'>
              <label htmlFor='teacher_name_input'>HOD's Name</label>
              <select
                name='teacher_name_input'
                id='teacher_name_input'
                onChange={(e) => {
                  setData({ ...data, hodName: e.target.value });
                }}
                value={data.hodName ? data.hodName : undefined}
              >
                <option value={undefined}>Select</option>
                {hod.length > 0 &&
                  hod.map((h, index) => (
                    <option
                      value={h}
                      key={index}
                    >
                      {h}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </span>
      </div>

      <div className='page_visit_filter_button_container'>
        <button
          type='button'
          className='filter_clear_all_btn'
          onClick={() =>
            setData({
              role: undefined,
              class: undefined,
              visitedOn: undefined,
              visitedTo: undefined,
              teacherName: undefined,
            })
          }
        >
          Clear All
        </button>
        <button
          type='button'
          className='filter_aply_btn'
          onClick={() => {
            closeModal();
            getFilteredData();
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
}

export default PageVisitFilter;
