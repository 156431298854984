import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TablePagination,
} from '@material-ui/core';
const { readCookie } = require('../Functions/Cookies.js');

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),

    '& thead th': {
      fontweight: 700,
      cursor: 'pointer',
      color: 'gray',

      backgroundColor: 'white',
    },
    '& tbody tr:hover': {
      backgroundColor: '#5CE0D2',
      cursor: 'pointer',
    },
    td: {
      width: '200px',
    },
  },
}));

function useTable(setData, headCells, length, page, setPage) {
  console.log(headCells, length, page);
  const classes = useStyles();
  const pages = [10, 25, 50, 100];

  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);

  // const schoolId = localStorage.getItem('schools');
  //table conmponent
  const TblContainer = (props) => (
    <Table className={classes.table}>{props.children}</Table>
  );
  const TblHead = (props) => {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((item) => (
            <TableCell key={item.id}>{item.label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChange = (event, newPage) => {
    console.log('handle', newPage, rowsPerPage);
    setPage(newPage);
    recordsAfterPagingAndSorting(newPage);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    const schoolId = localStorage.getItem('schoolId');

    // const data = await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/students?school=${schoolId}&page=${page + 1}&limit=${parseInt(event.target.value, 10)}`)
    // const arrayData = await data.json();
    // setData(arrayData.data.students)

    const DataType = localStorage.getItem('dataType');
    let signed = localStorage.getItem('signed');
    const SortBy = localStorage.getItem('sortBy');
    if (DataType && SortBy) {
      if (SortBy === 'desc') {
        signed = '-';
      } else {
        signed = '';
      }
      const data = await fetch(
        `${
          process.env.REACT_APP_ORIGIN
        }/api/v1/students?school=${schoolId}&page=${page + 1}&limit=${parseInt(
          event.target.value,
          10
        )}&sort=${signed}${DataType}`,
        { headers: { token: `${readCookie('token')}` } }
      );
      const arrayData = await data.json();
      setData(arrayData.data.students);
    } else {
      const data = await fetch(
        `${
          process.env.REACT_APP_ORIGIN
        }/api/v1/students?school=${schoolId}&page=${page + 1}&limit=${parseInt(
          event.target.value,
          10
        )}`,
        { headers: { token: `${readCookie('token')}` } }
      );
      const arrayData = await data.json();
      setData(arrayData.data.students);
    }
  };

  const recordsAfterPagingAndSorting = async (newPage) => {
    //take page also
    const schoolId = window.localStorage.getItem('schoolId');

    const DataType = localStorage.getItem('dataType');
    let signed = localStorage.getItem('signed');
    const SortBy = localStorage.getItem('sortBy');
    console.log(page, 'PAGES ');
    if (DataType && SortBy) {
      if (SortBy === 'desc') {
        signed = '-';
      } else {
        signed = '';
      }
      const data = await fetch(
        `${
          process.env.REACT_APP_ORIGIN
        }/api/v1/students?school=${schoolId}&page=${
          newPage + 1
        }&limit=${rowsPerPage}&sort=${signed}${DataType}`,
        { headers: { token: `${readCookie('token')}` } }
      );
      const arrayData = await data.json();
      setData(arrayData.data.students);
    } else {
      const data = await fetch(
        `${
          process.env.REACT_APP_ORIGIN
        }/api/v1/students?school=${schoolId}&page=${
          newPage + 1
        }&limit=${rowsPerPage}`,
        { headers: { token: `${readCookie('token')}` } }
      );
      const arrayData = await data.json();
      setData(arrayData.data.students);
    }
  };

  const TblPagination = () => (
    <TablePagination
      component='div'
      page={page}
      // labelRowsPerPage=''
      // rowsPerPageOptions={[]}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={length}
      onPageChange={handleChange}
      onRowsPerPageChange={handleChangeRowsPerPage}
    ></TablePagination>
  );

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
}

export default useTable;
