import React, { useState, useEffect } from 'react';
import CloseBtn from '../CloseBtn';
import './RegistrationInfo.css';
const { readCookie } = require('../../Functions/Cookies.js');

function RegistrationInfo() {
  const userId = window.localStorage.getItem('userId');

  const [signUpDate, setSignUpDate] = useState('YYYY-MM-DD');
  const [expiryDate, setExpiryDate] = useState('YYYY-MM-DD');

  const getProfileDetails = async () => {
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}`, {
      headers: {
        token: `${readCookie('token')}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setSignUpDate(response.data.staff.signUpDate.slice(0, 10));
        setExpiryDate(response.data.staff.expiryDate.slice(0, 10));
      });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  return (
    <div className='RegistrationInfo'>
      <div className='heading'>
        <p>Registration</p>
      </div>

      <CloseBtn></CloseBtn>

      <div className='information-container'>
        <div className='registration-date'>
          <p>Registration Date</p>
          <span>
            <p>{signUpDate}</p>
          </span>
        </div>

        <div className='expiry-date'>
          <p>Expiry Date</p>
          <span>
            <p>{expiryDate}</p>
          </span>
        </div>
      </div>
    </div>
  );
}

export default RegistrationInfo;
