import React from 'react'
import './Footer.css'
import  MainImage from './Images/footer.png'
import  FacebookIcon from './Images/fb.svg'
import InstagramIcon from './Images/ig.svg'
import LinkedinIcon from './Images/lk.svg'

function Footer() {
    return (
        <footer>
            <div className="upper">
                <div className="info">
                    <img src={MainImage} className="info-img" alt="" />
                    <span>
                        <a href=""><img src={InstagramIcon} alt="" id="icon1" /></a>
                        <a href=""><img src={FacebookIcon} alt="" /></a>
                        <a href=""><img src={LinkedinIcon} alt="" /></a>
                    </span>
                </div>
                <div className="links">
                    <span>
                        <p className="link-heading">Community</p>
                        <a href="">Create Account</a>
                    </span>
                    <span>
                        <p className="link-heading">Support</p>
                        <a href="">Terms & Conditions</a>
                        <a href="">Privacy Policy</a>
                        <a href="">Copyright Isuue</a>
                        <a href="">Get Help</a>
                    </span>
                    <span>
                        <p className="link-heading">Join Us</p>
                        <a href="">Student</a>
                        <a href="">Schools</a>
                        <a href="">Contact Us</a>
                        <a href="">Careers</a>
                    </span>
                </div>
            

                <div className="downloads">
                    <p className="link-heading">Download App</p>
                    <a href="">Apple ios</a>
                    <a href="">Google Play</a>
                </div>
            </div>

            <div className="lower">
                <p>Copyright © 2010-2019 2xCell</p>
            </div>           
        </footer>
    )
}

export default Footer
