import React from 'react';
import './search.css';
import SearchIcon from '@material-ui/icons/Search';
import ProfileImage from '../ProfileImage/ProfileImage';
const { readCookie } = require('../Functions/Cookies.js');

function Search({
  TblPagination,
  paginationCheck,
  searchStatus,
  setData,
  setLength,
}) {
  const schoolId = window.localStorage.getItem('schoolsId');

  const handleSearch = (e) => {
    let target = e.target;

    if (target === '') {
      fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/students?school=${schoolId}&page=1&limit=10`,
        {
          headers: {
            token: `${readCookie('token')}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data.data.students);
        });

      fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/students?school=${schoolId}`,
        {
          headers: {
            token: `${readCookie('token')}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLength(data.results);
        });
    } else {
      fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/students?school=${schoolId}&name[regex]=${target.value}&name[options]=i`,
        {
          headers: {
            token: `${readCookie('token')}`,
          },
        }
      )
        .then((data) => data.json())
        .then((res) => {
          setData(res.data.students);
        });
    }
  };

  return (
    <>
      <div className='searchNav'>
        <div className='searchNav-left'>
          <div className='searchNav-left-heading'>
            <h2>Students</h2>
          </div>
          <div className='searchNav-left-heading-pagination'>
            {paginationCheck === 'Yes' ? (
              <TblPagination></TblPagination>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/**Search Right */}
        <div className='searchNav-right'>
          <div className='searchNav-right-searchinput'>
            {searchStatus === 'Yes' ? (
              <>
                {' '}
                <input
                  type='text'
                  placeholder='Search Something'
                  onChange={handleSearch}
                ></input>
                <SearchIcon className='search-icon'></SearchIcon>
              </>
            ) : (
              ''
            )}
          </div>
          <div className='searchNav-right-user'>
            {/* <img src={image} alt='student'></img> */}
            <ProfileImage />
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
