import React, { useState } from 'react';
import user from '../../user-circle.svg';
import CloseBtn from '../CloseBtn';
import './Password.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { readCookie } = require('../../Functions/Cookies.js');

function Password() {
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);

  const schoolName = window.localStorage.getItem('schoolname');
  const branch = window.localStorage.getItem('branch');
  const imageUrl = window.localStorage.getItem('imageUrl');

  const updatePassword = async () => {
    const currentPassword = oldPassword;

    if (String(newPassword).length < 8) {
      toast.info('Password needs to be at least 8 characters');
      return;
    }

    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/auth/changepassword/?user=staff`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          token: `${readCookie('token')}`,
        },
        body: JSON.stringify({ currentPassword, newPassword }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.status == 'succcess') {
          toast.success('Password changed successfully');
          setOldPassword(newPassword);
          setNewPassword(null);
        } else {
          toast.error(response.message);
        }
      });
  };

  return (
    <div className='Password'>
      <div className='heading'>
        <p>Password</p>
      </div>

      <CloseBtn></CloseBtn>

      <div className='info-container'>
        <img src={imageUrl || user} id='edit-password-image' alt='' />
        <span>
          <p id='school-name'>{schoolName}</p>
          <p id='school-address'>{branch}</p>
        </span>
      </div>

      <div className='password-container'>
        <span>
          <label htmlFor='old-password'>Old Password</label>
          <input
            type='password'
            className='old-password'
            value={oldPassword}
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
          />
        </span>
        <span>
          <label htmlFor='new-password'>New Password</label>
          <input
            type='password'
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            name='nw-password'
          />
          <p className='password-message'>Minimum 8 characters</p>
        </span>
      </div>

      <div className='button-container'>
        <button
          className='save-password-btn'
          type='button'
          onClick={() => {
            updatePassword();
          }}
        >
          SAVE
        </button>
      </div>
    </div>
  );
}

export default Password;
