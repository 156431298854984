import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import user from '../../user-circle.svg';
import CloseBtn from '../CloseBtn';
import './EditProfile.css';
const { readCookie } = require('../../Functions/Cookies.js');

function EditProfile() {
  const userId = window.localStorage.getItem('userId');

  const [school, setSchool] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [branch, setBranch] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const getProfileDetails = async () => {
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}`, {
      headers: {
        token: `${readCookie('token')}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('====================================');
        console.log(response);
        console.log('====================================');
        setSchool(response.data.staff.school.name);
        setCity(response.data.staff.city);
        setState(response.data.staff.state);
        setBranch(response.data.staff.branch);
        setEmail(response.data.staff.email);
        setNumber(response.data.staff.contact);
        setImageUrl(response.data.staff.profile?.fileUrl || null);
      });
  };

  //Function to update the profile details
  const updateProfileDetails = async () => {
    const contact = parseInt(number);

    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}`, {
      method: 'PATCH',
      headers: {
        token: `${readCookie('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school,
        city,
        state,
        branch,
        email,
        contact,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status == 'success') {
          toast.success('Profile updated successfully');
        } else {
          toast.error(response.message);
        }
      });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  //Function to upload super admin picture
  const uploadPicture = async (e) => {
    setLoading((loading) => !loading);
    const userId = window.localStorage.getItem('userId');

    const fd = new FormData();
    fd.append('image', e.target.files[0], e.target.files[0].name);

    console.log(readCookie('token'));
    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}/profile`,
      {
        method: 'PATCH',
        headers: {
          token: `${readCookie('token')}`,
        },
        body: fd,
      }
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        setLoading((loading) => !loading);

        if (response.status == 'success') {
          window.localStorage.setItem(
            'imageUrl',
            response.data.profile.fileUrl
          );
          window.location.reload();
        } else {
          toast.error(response.message);
        }
      });
  };

  //Function to delete super admin picture
  const deletePicture = async () => {
    setLoading((loading) => !loading);

    const userId = window.localStorage.getItem('userId');

    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}/profile`,
      {
        method: 'DELETE',
        headers: {
          token: `${readCookie('token')}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("aaaaaaaaaaaaaaaaaaa===============>",response);
        setLoading((loading) => !loading);

        if (response.status === 'success') {
          window.location.reload();
        } else {
          toast.error(response.message);
        }
      });
  };

  return (
    <div className='EditProfile'>
      <div className='heading'>
        <p> Edit Profile </p>{' '}
      </div>{' '}
      <CloseBtn> </CloseBtn>{' '}
      {/* For the image and buttons on top of the page   */}{' '}
      <div className='image-container'>
        <img className='profile-image' src={imageUrl || user} alt='Profile' />{' '}
        {/* <button className="upload-picture-btn">UPLOAD PICTURE</button> */}{' '}
        <input
          type='file'
          name='upload-picture'
          id='upload-picture'
          className='upload-picture'
          disabled={loading}
          onChange={(e) => {
            uploadPicture(e);
          }}
        />
        <label htmlFor='upload-picture' className='upload-picture-label'>
          UPLOAD PICTURE
        </label>
        <button
          className='delete-picture-btn'
          onClick={() => {
            deletePicture();
          }}
          disabled={loading}
        >
          {' '}
          DELETE{' '}
        </button>{' '}
      </div>{' '}
      {/* input fields  */}{' '}
      <div className='form-container'>
        <span className='school-name-section'>
          <label htmlFor='school-name'> School </label>{' '}
          <input
            type='text'
            name='school-name'
            // value={school}
            defaultValue={school}
            required
            className='school-name'
          />
        </span>{' '}
        <span className='address'>
          <span>
            <label htmlFor='branch'> Branch </label>{' '}
            <select name='branch' id='branch' value={branch}>
              <option value='Sector 62'> Sector 62 </option>{' '}
            </select>{' '}
          </span>{' '}
          <span>
            <label htmlFor='city'> City </label>{' '}
            <select name='city' id='city' value={city}>
              <option value='Noida'> Noida </option>{' '}
            </select>{' '}
          </span>{' '}
          <span>
            <label htmlFor='state'> State </label>{' '}
            <select name='state' id='state' value={state}>
              <option value='Uttar Pradesh'> Uttar Pradesh </option>{' '}
              <option value='Delhi'> Delhi </option>{' '}
              <option value='Haryana'> Haryana </option>{' '}
            </select>{' '}
          </span>{' '}
          <span>
            <label htmlFor='Country'> Country </label>{' '}
            <select name='Country' id='Country' value={country}>
              <option value='India'> India </option>{' '}
              <option value='Bangladesh'> Bangladesh </option>{' '}
              <option value='Sri Lanka'> Sri Lanka </option>{' '}
            </select>{' '}
          </span>{' '}
        </span>{' '}
        <span className='contact'>
          <span>
            <label htmlFor='email'> Email </label>{' '}
            <input
              type='email'
              name='email'
              className='email'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />{' '}
          </span>
          <span>
            <label htmlFor='number'> Primary Contact </label>{' '}
            <input
              type='number'
              name='number'
              className='number'
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
            />{' '}
          </span>{' '}
        </span>{' '}
      </div>{' '}
      <div className='button-container'>
        <button
          className='save-profile-btn'
          type='button'
          onClick={() => {
            updateProfileDetails();
          }}
        >
          {' '}
          SAVE{' '}
        </button>{' '}
      </div>{' '}
    </div>
  );
}

export default EditProfile;
