import React, { useState, useEffect } from 'react';
import './LoginBox.css';
import { useHistory } from 'react-router-dom';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { createCookie } = require('../../Functions/Cookies.js');
const { ValidateToken } = require('../../Functions/Validation.js');

function LoginBox() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const TogglePassword = () => {
    if (document.getElementById('visible').classList.contains('hidden')) {
      document.getElementById('password-field').type = 'password';
      document.getElementById('visible').classList.remove('hidden');
      document.getElementById('invisible').classList.add('hidden');
    } else {
      document.getElementById('password-field').type = 'text';
      document.getElementById('invisible').classList.remove('hidden');
      document.getElementById('visible').classList.add('hidden');
    }
  };

  const history = useHistory();

  //User verification will be done here
  const verifyUser = () => {
    //Check if fields are empty
    if (!username || !password) {
      toast.warning('Fields canot be empty');
      return;
    }

    const email = username;
    console.log('signing in');

    fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/auth/login?user=staff`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (response.status == 'success') {
          //Save the token and expiresIn in cookies
          createCookie('token', response.data.token, response.data.expiresIn);

          //Save response data in the local storage
          window.localStorage.setItem('userId', response.data.payload.userId);
          window.localStorage.setItem('name', response.data.payload.name);
          window.localStorage.setItem('role', response.data.payload.role);
          window.localStorage.setItem(
            'schoolsId',
            response.data.payload.school._id
          );
          window.localStorage.setItem(
            'imageUrl',
            response.data.payload.profile.fileUrl || null
          );

          window.localStorage.setItem('logged_in', true);
          window.location.reload();
        } else {
          toast.error(response.message);
        }
      });
  };

  toast.configure();

  return (
    <div className='LoginBox'>
      <div className='top-section'>
        <h3>Sign In</h3>
        <p>Sign in with your details</p>
      </div>

      <div className='input-container'>
        <input
          type='text'
          className='input-username'
          placeholder='Username or email address'
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <span>
          <input
            type='password'
            className='input-password'
            id='password-field'
            placeholder='Password'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button
            className='toggle-password'
            onClick={() => {
              TogglePassword();
            }}
          >
            <VisibilityOutlinedIcon id='visible' />
            <VisibilityOffOutlinedIcon className='hidden' id='invisible' />
          </button>
        </span>
        <button
          className='signin-btn'
          onClick={() => {
            verifyUser();
          }}
        >
          Sign in to my account
        </button>
      </div>

      <div className='bottom-section'>
        <p>
          Don't have an account? <a href=''>Sign Up</a>
        </p>
        <p>
          Forgot password? <a href=''>Password Reset</a>
        </p>
      </div>
    </div>
  );
}

export default LoginBox;
