import React from 'react';
import './searchgrade.css';
import { Link } from 'react-router-dom';
import ProfileImage from '../ProfileImage/ProfileImage';
function Search({
  TblPagination,
  records,
  setFilterFn,
  school,
  paginationCheck,
  searchStatus,
  crossStatus,
  setPageData,
  searchBy,
}) {
  // const handleSearch = (e) => {
  // let target = e.target;
  //         // fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/countries?page=1&limit=10`).then((data) => data.json()).then((res) => {
  //         //     setPageData(res.data.countries)
  //         // })
  // }
  // else {
  //         // fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/countries?name[regex]=${target.value}&name[options]=i`).then((data) => data.json()).then((res) => {
  //         //     setPageData(res.data.countries)
  //         // })
  //     }
  // };

  return (
    <>
      <div className='searchNav'>
        <div className='searchNav-left'>
          <div className='searchNav-left-heading'>
            <h2>Role Mapping</h2>
          </div>
        </div>
        {/**Search Right */}
        <div className='searchNav-right' style={{ marginTop: '20px' }}>
          <div className='searchNav-right-searchinput'></div>
          <div className='searchNav-right-user'>
            <Link to='/profile/edit'>
              {/* <img src={imageUrl ? imageUrl : user}></img> */}
              <ProfileImage />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
