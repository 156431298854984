import React, { useState, useEffect } from 'react';
import './student.css';
import useTable from './useTableStudent';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import Myswitch from './Myswitch';
import { useJsonToCsv } from 'react-json-csv';
import { useHistory } from 'react-router-dom';
import FilterListIcon from '@material-ui/icons/FilterList';
import Search from './Seach';
import AddStudent from '../AllModal/AddStudent';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import exportFromJSON from 'export-from-json';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';
import CSVtoJSON from '../Functions/CSVtoJSON';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StudentModal from '../AllModal/student';
const { ValidateStudent } = require('../Functions/Validation.js');
const { readCookie } = require('../Functions/Cookies.js');

const fields = {
  schoolname: 'School',
  board: 'board',
  branch: 'branch',
  city: 'City',
  state: 'State',
  students: 'Student',
  teachers: 'teachers',
  expirydate: 'expdate',
};

//labelling of data in table view on component render
const headCells = [
  { id: 1, label: '  Name' },
  { id: 2, label: 'Role' },
  { id: 3, label: ' Email' },
  { id: 4, label: ' Contact ' },
  { id: 5, label: 'Signup Date ' },
  { id: 6, label: ' Expiry Date' },
  { id: 7, label: '' },
];

const exportCsv = () => {
  const schoolId = window.localStorage.getItem('schoolsId');
  fetch(
    `${process.env.REACT_APP_ORIGIN}/api/v1/students?school=${schoolId}&fields=name,email,contact,signUpDate,expiryDate&export=true`,
    {
      headers: {
        token: `${readCookie('token')}`,
      },
    }
  )
    .then((res) => res.json())
    .then((studentData) => {
      console.log("Raw student data for CSV ====>",studentData);
      const data = studentData.data.students.map(item=>({
        "School Name":item.school.name,
        "Name":item.name,
        "Branch":item.school.branch,
        "Role":"Student",
        "Email":item.email,
        "Contact":item.contact,
        "SignUp Date":new Date(item.signUpDate).toLocaleDateString(),
        "Expiry Date":new Date(item.expiryDate).toLocaleDateString(),
      }));
      const fileName = 'Students';
      const exportType = exportFromJSON.types.csv;
      console.log("Parsed student data for CSV ====>",data);

      exportFromJSON({ data, fileName, exportType });
    });
};

function Student() {
  console.log('st');
  const schoolId = window.localStorage.getItem('schoolsId');

  const [crossStatus, setCrossStatus] = useState('No');
  const filename = 'csvfile'; //hooks for csv files generating
  const { saveAsCsv } = useJsonToCsv(); //save csv file  from json
  const [viewType, setViewType] = useState('card');
  const history = useHistory();

  const [data, setData] = useState([]);
  const [length, setLength] = useState(0);
  const [page, setPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);

  const [fetched, setFetched] = useState(false);

  //Filter Teacher
  const addFilter = () => {
    // filter
    var modal = document.getElementsByClassName('filterStudent')[0];
    modal.style.top = '50%';
  };

  const addStudent = () => {
    var modal = document.getElementsByClassName('addStudent')[0];
    modal.style.top = '50%';
  };

  //Sidebar Shrink
  const sideBarShrink = () => {
    var sideBar = document.getElementsByClassName('Sidebar')[0];
    var menuName = document.getElementsByClassName('menu-Name');
    var logout = document.getElementsByClassName('logout')[0];
    var aboveDiv = document.getElementsByClassName('Above-div')[0];
    var lowerDiv = document.getElementsByClassName('Lower-div')[0];
    lowerDiv.style.marginTop = '150px';
    aboveDiv.style.display = 'none';
    logout.style.display = 'none';
    sideBar.style.flex = '0.1';

    for (var i = 0; i < menuName.length; i++) {
      menuName[i].style.display = 'none';
    }
  };

  useEffect(() => {
    const fetchApi = async () => {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/students?school=${schoolId}&page=1&limit=10`,
        {
          headers: {
            token: `${readCookie('token')}`,
          },
        }
      )
        .then((res) => res.json())
        .then(async (data) => {
          setData(data.data?.students);
          console.table(data);
          localStorage.removeItem('dataType');
          localStorage.removeItem('signed');
          localStorage.removeItem('sortBy');

          await fetch(
            `${process.env.REACT_APP_ORIGIN}/api/v1/students/total?school=${schoolId}`,
            {
              headers: {
                token: `${readCookie('token')}`,
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              setLength(data.data?.totalStudents);
              setFetched(true);
            });
        });
    };
    fetchApi();
  }, []);

  //sort by function
  const handleSort = async (DataType, SortBy) => {
    //

    let signed = '';
    if (SortBy == 'desc') {
      signed = '-';
    }
    //checking page condition

    //set data in localStorage
    localStorage.setItem('dataType', DataType);
    localStorage.setItem('signed', signed);
    localStorage.setItem('sortBy', SortBy);

    await fetch(
      `${
        process.env.REACT_APP_ORIGIN
      }/api/v1/students?school=${schoolId}&sort=${signed}${DataType}&page=${
        page + 1
      }&limit=10`,
      {
        headers: {
          token: `${readCookie('token')}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data.data?.students);
      });
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(setData, headCells, length, page, setPage); //user defined hooks

  //Function to upload converted csv file
  const uploadCSV = async (e) => {
    if (!e.target.files.length) {
      toast.error('File is empty');
      return;
    }

    //To upload csv-to-json file to the server
    const reader = new FileReader();

    // function read() {

    //Extract contents of the CSV file
    const csv = e.target.files[0];
    reader.readAsText(csv);

    reader.onload = function (e) {
      let emailList = [];
      let contactList = [];

      //Convert CSV content to JSON
      const jsonContent = CSVtoJSON(e.target.result, emailList, contactList);

      if (jsonContent.length > 1000) {
        toast.error('Only 1000 users entries per file');
        return;
      }

      //Validate content of JSON
      jsonContent.splice(-1);

      let validated = false,
        allCorrect = true;
      // jsonContent.forEach((student, index) => {
      //   validated = ValidateStudent(student, emailList, contactList);
      //   if (!validated) {
      //     allCorrect = false;
      //     toast.error(`Error in entry ${index + 1}`);
      //     return;
      //   }
      // });

      if (emailList.length !== new Set(emailList).size) {
        allCorrect = false;
        toast.error(`Duplicate emails found`);
      }

      // if (contactList.length !== new Set(contactList).size) {
      //     allCorrect = false
      // }

      //Proceed only if all are correct
      if (allCorrect) {
        const schoolId = window.localStorage.getItem('schoolsId');
        const docs = jsonContent;

        try {
          fetch(
            `${process.env.REACT_APP_ORIGIN}/api/v1/schools/${schoolId}/import-v2?user=student`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                token: `${readCookie('token')}`,
              },
              body: JSON.stringify({ docs }),
            }
          )
            .then((response) => response.json())
            .then((response) => {
              console.log(response);
              if (
                response.status == 'error' ||
                response.status == 'fail' ||
                !response.status
              ) {
                toast.error(response.message);
                document.getElementById('student-file-input').value = '';
                // window.location.reload()
                return;
              } else {
                toast.success(
                  'Students imported successfully. Kindly refresh to see updates.'
                );
                document.getElementById('student-file-input').value = '';
                const logs = response?.errorLogs;
                if (logs) {
                  exportFromJSON({
                    data: logs,
                    fileName: 'STUDENT_EXPORT_LOGS',
                    exportType: exportFromJSON.types.csv,
                  });
                }
                if (response.errors) {
                  response.errorLogs.map((item) => {
                    toast.error(item);
                  });
                }
                // window.location.reload()
              }
            });
        } catch (e) {
          toast.error(e.message);
          console.log(e);
        }
      } else {
        console.log('Error in the CSV');
        toast.error('Error in CSV');
      }
    };
  };

  return (
    <div className='student'>
      {/* <MemberModal></MemberModal> */}
      <StudentModal setData={setFilteredData} />

      <AddStudent setData={setData} setLength={setLength}></AddStudent>
      <div className='student_upper_body'>
        {/**SubMENU */}
        <Search
          searchStatus='Yes'
          TblPagination={TblPagination}
          setData={setData}
          setLength={setLength}
          school={'TEACHER'}
          paginationCheck={'Yes'}
          crossStatus={crossStatus}
        ></Search>
      </div>
      <div className='student_lower_body'>
        <div className='student_MainBody_RIGHT'>
          {/**Teacher mid*/}
          <div className='student_MainBody_Right_mid'>
            <div
              className='student_MainBody_Right_mid_left'
              onClick={() => {
                addStudent();
              }}
              style={{ cursor: 'pointer' }}
            >
              <AddBoxOutlinedIcon className='item'></AddBoxOutlinedIcon> Add
              Student
            </div>
            <div
              className='student_MainBody_Right_mid_left'
              style={{
                cursor: 'pointer',
                marginRight: 'auto',
                paddingLeft: '10px',
              }}
              onClick={() => {}}
            >
              <PlaylistAddOutlinedIcon className='item'></PlaylistAddOutlinedIcon>{' '}
              Import Students
              <input
                type='file'
                className='teacher-file-input'
                id='student-file-input'
                accept='.csv'
                onChange={(e) => {
                  uploadCSV(e);
                }}
              />
            </div>
            <div className='student_MainBody_Right_mid_right'>
              <div className='item-1 item' onClick={exportCsv}>
                <SystemUpdateAltOutlinedIcon className='student_MainBody_Right_mid_right_icon'></SystemUpdateAltOutlinedIcon>{' '}
                Export Csv
              </div>
              <div className='item-2 item'>
                <Menu
                  menuButton={
                    <MenuButton>
                      <ImportExportIcon className='student_MainBody_Right_mid_right_icon'></ImportExportIcon>{' '}
                      Sort By
                    </MenuButton>
                  }
                >
                  <SubMenu label='Name'>
                    <MenuItem
                      onClick={() => {
                        handleSort('name', 'asc');
                      }}
                    >
                      Ascending
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleSort('name', 'desc');
                      }}
                    >
                      Descending
                    </MenuItem>
                  </SubMenu>
                  <SubMenu label='SignUpDate'>
                    <MenuItem
                      onClick={() => {
                        handleSort('signUpDate', 'asc');
                      }}
                    >
                      Ascending
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleSort('signUpDate', 'desc');
                      }}
                    >
                      Descending
                    </MenuItem>{' '}
                  </SubMenu>
                  <SubMenu label='ExpiryDate'>
                    <MenuItem
                      onClick={() => {
                        handleSort('expiryDate', 'asc');
                      }}
                    >
                      Ascending
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleSort('expiryDate', 'desc');
                      }}
                    >
                      Descending
                    </MenuItem>
                  </SubMenu>
                </Menu>
              </div>
              <div
                className='item-3 item'
                onClick={() => {
                  addFilter();
                }}
              >
                <FilterListIcon> </FilterListIcon> &nbsp;Filter
              </div>
            </div>
          </div>
          {/**Teacher right cards or table view*/}
          {fetched ? (
            <TblContainer>
              <TblHead></TblHead>
              <TableBody>
                {(filteredData.length ? filteredData : data).map(
                  (item, idx) => {
                    var expiryDate = item.expiryDate.split('T');
                    var signUpDate = item.signUpDate.split('T');
                    var name =
                      item.name.charAt(0).toUpperCase() + item.name.slice(1);
                    var role =
                      item.role.charAt(0).toUpperCase() + item.role.slice(1);
                    return (
                      <>
                        <TableRow key={item.name}>
                          <TableCell
                            onClick={() => {
                              localStorage.setItem('studentName', name);
                              localStorage.setItem('studentId', item._id);
                              history.push('/dashboard/student');
                            }}
                          >
                            {name}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              localStorage.setItem('studentName', name);
                              localStorage.setItem('studentId', item._id);
                              history.push('/dashboard/student');
                            }}
                          >
                            {item.role}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              localStorage.setItem('studentName', name);
                              localStorage.setItem('studentId', item._id);
                              history.push('/dashboard/student');
                            }}
                          >
                            {item.email}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              localStorage.setItem('studentName', name);
                              localStorage.setItem('studentId', item._id);
                              history.push('/dashboard/student');
                            }}
                          >
                            {item.contact}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              localStorage.setItem('studentName', name);
                              localStorage.setItem('studentId', item._id);
                              history.push('/dashboard/student');
                            }}
                          >
                            {signUpDate[0]}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              localStorage.setItem('studentName', name);
                              localStorage.setItem('studentId', item._id);
                              history.push('/dashboard/student');
                            }}
                          >
                            {expiryDate[0]}
                          </TableCell>
                          <TableCell>
                            <Myswitch enabled={item.enabled}></Myswitch>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  }
                )}
              </TableBody>
            </TblContainer>
          ) : (
            <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
              <h3>Loading...</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Student;
