import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { createCookie } = require('../Functions/Cookies.js');

function Auth() {
  console.log('auth enetered');

  const url = window.location.href.split('/');
  const token = url[url.length - 2];
  const id = url[url.length - 1];

  window.localStorage.setItem('authToken', token);
  window.localStorage.setItem('userId', id);

  console.log('token and id extracted');

  const getUserDetails = async () => {
    console.log('starting function');
    try {
      await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${id}`, {
        headers: {
          token: token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (response.status == 'success') {
            //Save the token and expiresIn in cookies
            createCookie('token', token);

            //Save response data in the local storage
            window.localStorage.setItem('userId', response.data.staff._id);
            window.localStorage.setItem('name', response.data.staff.name);
            window.localStorage.setItem('role', response.data.staff.role);
            window.localStorage.setItem(
              'schoolsId',
              response.data.staff.school._id
            );
            if (response.data.staff.profile) {
              if (response.data.staff.profile.fileUrl) {
                window.localStorage.setItem(
                  'imageUrl',
                  response.data.staff.profile.fileUrl
                );
              } else {
                window.localStorage.setItem('imageUrl', 'null');
              }
            } else {
              window.localStorage.setItem('imageUrl', 'null');
            }

            window.localStorage.setItem('logged_in', true);
            window.location.href = '/';
          } else {
            toast.error(response.message);
            window.location.href = `${process.env.REACT_APP_REDIRECT}`;
          }
        });
    } catch (error) {
      toast.error(error);
      console.log(error);
      window.location.href = `${process.env.REACT_APP_REDIRECT}`;
    }
  };

  getUserDetails();

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <p style={{ marginTop: '2rem' }}>Authenticating.Please wait..</p>
    </div>
  );
}

export default Auth;
