import React, { useState, useEffect } from 'react';
import { readCookie } from '../Functions/Cookies';
import Notifications, { notify } from 'react-notify-toast';

const StudentModal = ({ setData }) => {
  const [studentClass, setClass] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [sections, setSection] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  useEffect(() => {
    const schoolId = window.localStorage.getItem('schoolsId');

    fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/grades?schoolId=${schoolId}`,
      {
        headers: {
          token: `${readCookie('token')}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data.grades, 2222);
        const grade =
          res.data?.grades?.map((grade) => {
            return grade;
          }) || [];

        setClass(grade);
        const section = grade.length ? grade[0].sections : [];
        setSection(section);
      });
  }, []);
  const notification = (data) => {
    notify.show(data, 'custom', 2000, { background: 'red', text: 'white' });
  };
  const filterData = () => {
    const schoolId = localStorage.getItem('schoolsId');

    if (!(selectedClass || selectedSection || expiryDate)) {
      notification('Please fill out fields');
      return;
    }
    fetch(
      `${
        process.env.REACT_APP_ORIGIN
      }/api/v1/students?school=${schoolId}&grade=${selectedClass}&section=${selectedSection}${
        expiryDate
          ? '&expiryDate[lte]=' + new Date(expiryDate).toISOString()
          : ''
      }`,
      {
        headers: {
          token: `${readCookie('token')}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        const students = res?.data?.students;
        if (students && students?.length) {
          setData(students);
          closeModal();
          return;
        }
        notification('No data found');
      });
  };

  const closeModal = () => {
    var closeModal = document.getElementsByClassName('filterStudent')[0];
    closeModal.style.top = '-47%';
  };

  const onSelectClass = (e) => {
    const value = e.target.value;
    setSelectedClass(value);
    const sectionList = studentClass.filter((grades) => grades.grade === value);
    const sections = sectionList.length ? sectionList[0].sections : [];

    setSection(sections);
  };

  return (
    <div
      className='filterStudent'
      style={{ height: 'fit-content', padding: '20px' }}
    >
      <div>
        <h2 style={{ marginBlockStart: '0px' }}>Filter</h2>
      </div>
      <div className='filterMember_form'>
        <div className='filter_studen_class_section'>
          <div className='filter_role'>
            <label>Select Class</label>
            <br></br>
            <select placeholder='none' onChange={onSelectClass}>
              <option value='' defaultValue='Select'>
                Select
              </option>
              {studentClass.length &&
                studentClass.map(({ grade }) => (
                  <option value={grade} key={grade}>
                    {grade}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className='filter_role'>
          <label>Select Section</label>
          <br></br>
          <select
            placeholder='none'
            onChange={(e) => setSelectedSection(e.target.value)}
          >
            <option value='select'>Select</option>
            {sections.length &&
              sections.map(({ section }) => (
                <option value={section} key={section}>
                  {section}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className='filter_signupDate_filter_ExpiryDate'>
        <div className='filter_signupDate'>
          <label>Select Expiry Date</label>
          <br></br>
          <input
            type='date'
            onChange={(e) => {
              setExpiryDate(e.target.value);
            }}
          ></input>
        </div>
      </div>

      <div className='filter-teacher-btn'>
        <div className='filter_createBtn'>
          <button onClick={filterData}>Apply</button>
        </div>
        <div
          className='filter_cancelBtn'
          onClick={() => {
            closeModal();
          }}
        >
          <button>Cancel</button>
          <Notifications options={{ zIndex: 200, top: '50px' }} />
        </div>
      </div>
    </div>
  );
};

export default StudentModal;
