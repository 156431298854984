import React from 'react';
import './main.css';
import Dashboard from '../Dashboard/Dashboard';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { TeacherProfile } from '../Teacher/TeacherInnerProfile/Teachers';
import Sidebar from '../Sidebar/Sidebar';
import Teacher from '../Teacher/Teacher';
import { StudentProfile } from '../Student/StudentInnerProfile/Student';
import Student from '../Student/Student';
import Grade from '../Grade/Grade';
import Role from '../RoleMapping/Role';
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar';
// import Profile from '../Profile/Profile';
import EditProfile from '../Profile/EditProfile/EditProfile';
import Password from '../Profile/Password/Password';
import RegistrationInfo from '../Profile/RegistrationInfo/RegistrationInfo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from '../auth/Auth';
import PrivateRoute from '../Routes/PrivateRoute';

const Main = () => {
  toast.configure();
  return (
    <div className='main'>
      <BrowserRouter>
        <Switch>
          <PrivateRoute path='/' exact>
            <>
              <Sidebar></Sidebar>
              <Dashboard></Dashboard>
            </>
          </PrivateRoute>
          <Route exact path='/auth/:token/:id' component={Auth} />
          <PrivateRoute path='/profile/edit' exact>
            <>
              <ProfileSidebar></ProfileSidebar>
              <EditProfile></EditProfile>
            </>
          </PrivateRoute>

          <PrivateRoute path='/profile/password' exact>
            <>
              <ProfileSidebar></ProfileSidebar>
              <Password></Password>
            </>
          </PrivateRoute>

          <PrivateRoute path='/profile/registration-info' exact>
            <>
              <ProfileSidebar></ProfileSidebar>
              <RegistrationInfo></RegistrationInfo>
            </>
          </PrivateRoute>

          {/* Routes for the main pages   */}
          <PrivateRoute path='/' exact>
            <>
              <Sidebar></Sidebar>
              <Dashboard></Dashboard>
            </>
          </PrivateRoute>

          <PrivateRoute path='/teacher' exact>
            <>
              <Sidebar></Sidebar>
              <Teacher></Teacher>
            </>
          </PrivateRoute>

          <PrivateRoute path='/dashboard/teacher' exact>
            <>
              <Sidebar></Sidebar>
              <TeacherProfile></TeacherProfile>
            </>
          </PrivateRoute>

          <PrivateRoute path='/student'>
            <>
              <Sidebar></Sidebar>
              <Student></Student>
            </>
          </PrivateRoute>

          <PrivateRoute path='/dashboard/student' exact>
            <>
              <Sidebar></Sidebar>
              <StudentProfile></StudentProfile>
            </>
          </PrivateRoute>

          <PrivateRoute path='/grade' exact>
            <>
              <Sidebar></Sidebar>
              <Grade></Grade>
            </>
          </PrivateRoute>

          <PrivateRoute path='/role' exact>
            <>
              <Sidebar></Sidebar>
              <Role></Role>
            </>
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Main;
