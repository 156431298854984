import React, { useState } from 'react';
import './MemberModal.css';
import { readCookie } from '../Functions/Cookies';
import Notifications, { notify } from 'react-notify-toast';

function MemberModal({ setData, page }) {
  const closeModal = () => {
    var closeModal = document.getElementsByClassName('filterMember')[0];
    closeModal.style.top = '-47%';
  };

  const notification = (data) => {
    notify.show(data, 'custom', 2000, { background: 'red', text: 'white' });
  };
  const [role, setRole] = useState(null);
  const [signupDate, setSignupDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);

  const filterStudent = () => {
    const schoolsId = localStorage.getItem('schoolsId');
    if (!(role || signupDate || expiryDate)) {
      notification('Please fill fields');
      return;
    }
    const constructreq = `${
      process.env.REACT_APP_ORIGIN
    }/api/v1/staffs?school=${schoolsId}${role ? `&role=${role}` : ''}${
      expiryDate ? `&expiryDate[lte]=${expiryDate}` : ''
    }${signupDate ? `&signUpDate[gte]=${signupDate}` : ''}`;
    fetch(constructreq, {
      headers: {
        token: `${readCookie('token')}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const staffs = res?.data?.staffs;
        if (staffs && staffs?.length) {
          setData(staffs);
          closeModal();
          return;
        }
        notification('No staff found');
      })
      .catch((e) => console.log(e));
  };

  return (
    <div
      className='filterMember'
      style={{ height: 'fit-content', padding: '20px' }}
    >
      <div>
        <h2 style={{ marginBlockStart: '0px' }}>Filter</h2>
      </div>
      <div className='filterMember_form'>
        <div className='filter_school'>
          {/* <label>Select School</label>
          <br></br>
          <select>
            <option value='dnp'>dnp</option>
            <option value='snp'>snp</option>
            <option value='cnp'>cnp</option>
            <option value='pnp'>pnp</option>
          </select> */}
        </div>
        <div className='filter_branch_role'>
          {/* <div className='filter_branch'> */}
          {/* <label>Select Branch</label>
            <br></br>
            <select placeholder='none'>
              <option value='cse'>cse</option>
              <option value='ece'>ece</option>
              <option value='chemical'>chemical</option>
              <option value='ee'>ee</option>
            </select> */}
          {/* </div> */}
          <div className='filter_role'>
            <label>Select Role</label>
            <br></br>
            <select
              placeholder='none'
              onChange={(e) => {
                setRole(e.target.value);
              }}
            >
              <option value='' defaultValue='Select' disabled>
                Select
              </option>
              <option value='school admin'>School Admin</option>
              <option value='principal'>Principal</option>
              <option value='hod'>HOD</option>
              <option value='teacher'>Teacher</option>
            </select>
          </div>
        </div>
        <div className='filter_signupDate_filter_ExpiryDate'>
          <div className='filter_signupDate'>
            <label>Select SignUp Date</label>
            <br></br>
            <input
              type='date'
              onChange={(e) => {
                setSignupDate(e.target.value);
              }}
            ></input>
          </div>
          <div className='filter_expiryDate'>
            <label>Select Expiry Date</label>
            <br></br>
            <input
              type='date'
              onChange={(e) => {
                setExpiryDate(e.target.value);
              }}
            ></input>
          </div>
        </div>

        <div className='filter-teacher-btn'>
          <div className='filter_createBtn'>
            <button onClick={filterStudent}>Apply</button>
          </div>
          <div
            className='filter_cancelBtn'
            onClick={() => {
              setData([]);
              closeModal();
            }}
          >
            <button>Cancel</button>
            <Notifications options={{ zIndex: 200, top: '50px' }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberModal;
