import React, { useState, useEffect } from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
// import useTable from './scheduleTable';
import './student.css';
// import Myswitch from './MySwitch';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
// import Select from 'react-select';
import Notifications, { notify } from 'react-notify-toast';
const { readCookie } = require('../../Functions/Cookies.js');

// const fields = {
//   schoolname: 'School',
//   board: 'board',
//   branch: 'branch',
//   city: 'City',
//   state: 'State',
//   students: 'Student',
//   teachers: 'teachers',
//   expirydate: 'expdate',
// };

function Schedule({
  TblContainer,
  TblHead,
  TblPagination,
  recordsAfterPagingAndSorting,
}) {
  const notification = (data) => {
    let myColor = { background: '#5CE0D2', text: 'black' };
    notify.show(data, 'custom', 2000, myColor);
  };

  // const addData = () => {
  //   var modal = document.getElementsByClassName('addTeacher')[0];
  //   modal.style.top = '50%';
  // };
  const mapSubjectWihTeachers = (subjects, subjectTeachers) => {
    for (let subject of subjects) {
      const teachers = subjectTeachers.filter(
        (subTeacher) => subTeacher.subject === subject.subject
      );
      if (!teachers.length) continue;
      subject['teachers'] = teachers.map((teacher) => {
        return teacher.teacher.name;
      });
    }
    return subjects;
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    const studentId = localStorage.getItem('studentId');

    fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/students/${studentId}`, {
      headers: {
        token: `${readCookie('token')}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const studentGrade = data.data.student.grade;
        const section = data.data.student.section;
        const schoolId = localStorage.getItem('schoolsId');
        // subjectTeacher
        fetch(
          `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${studentGrade}/sections/${section}/subjectTeachers?schoolId=${schoolId}`,
          {
            headers: {
              token: `${readCookie('token')}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log(data, 'teach');
            // setData(data.data.subjects);
            if (data?.status === 'error') {
              notification(data?.message);
              console.log(data);
              return;
            }
            const teacher = data.data.subjects;
            // setData(data.data.subjects);
            // fetch subjects
            fetch(
              `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${studentGrade}/sections/${section}/subjects?schoolId=${schoolId}`,
              {
                headers: {
                  token: `${readCookie('token')}`,
                },
              }
            )
              .then((data) => data.json())
              .then((res) => {
                console.log(res.data, 'subjects');
                const subjects = res.data.subjects;
                console.log(teacher, subjects);
                const data = mapSubjectWihTeachers(subjects, teacher);
                console.log(data, 'data');
                setData(data);
                //  setSubjects(res.data.subjects);
              });
          });
      });
  }, []);

  return (
    <div
      id='schedule1'
      className='teacher_right_side_bottom_right_schedule VerticalTabs'
    >
      <div className='upper_div_section'>
        <div className='upper_div_section_left'></div>
        <div className='upper_div_section_right'>
          {/**onClick={() => { saveAsCsv({ data, fields, filename }) }} */}
          <div className='upper_div_section_right_export_csv'>
            <SystemUpdateAltOutlinedIcon></SystemUpdateAltOutlinedIcon>&nbsp;
            Export Csv
          </div>
          <div className='upper_div_section_right_sort'></div>
        </div>
      </div>
      <div className='below_div_section'>
        <TblContainer>
          <TblHead></TblHead>
          <TableBody>
            {data
              ? data.map((item, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.subject}</TableCell>
                    {item?.teachers?.length ? (
                      <TableCell>{item?.teachers.join(',')}</TableCell>
                    ) : (
                      <TableCell>Not Assigned</TableCell>
                    )}
                  </TableRow>
                ))
              : ''}
          </TableBody>
        </TblContainer>
        {/**Notification Messages */}
        <Notifications options={{ zIndex: 200, top: '50px' }} />
      </div>
    </div>
  );
}

export default Schedule;
