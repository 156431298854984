import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { readCookie } from '../Functions/Cookies';
import { ValidateToken } from '../Functions/Validation';
import Login from '../Login/Login';

const PrivateRoute = ({ component: Component, children, ...rest }) => {
  const [loggedIn, setLoggedIn] = useState(
    JSON.parse(window.localStorage.getItem('logged_in'))
  );
  const validate = () => {
    ValidateToken().then((response) => {
      setLoggedIn(response);
    });
  };

  useEffect(() => {
    const cookie = readCookie('token');
    if (!cookie || cookie === '') {
      setLoggedIn(false);
    } else {
      validate();
      // setLoggedIn(true);
    }
  }, []);
  console.log(loggedIn, 23, children);
  return (
    <Route {...rest} render={() => (loggedIn ? children : <Login />)}></Route>
  );
};

export default PrivateRoute;
