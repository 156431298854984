import React from 'react'
import './Login.css'
import Navbar from './Navbar/Navbar'
import LoginBox from './LoginBox/LoginBox'
import Footer from './Footer/Footer'
import Wave from './wave.png'

function Login() {
    return (
        <div className="Login">
            <div className="bg-1"></div>
            <img src={Wave} className="wave" alt="" />
            <Navbar></Navbar>
            <LoginBox></LoginBox>
            <Footer></Footer>
        </div>
    )
}

export default Login
