import React, { useState, useEffect } from 'react';
import '../Sidebar/sidebar.css';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
const { readCookie, deleteCookie } = require('../Functions/Cookies.js');

function ProfileSidebar() {
  const [imageUrl, setImageUrl] = useState('');
  const adminName = window.localStorage.getItem('name');

  const getProfileDetails = async () => {
    try {
      const userId = window.localStorage.getItem('userId');
      await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}`, {
        headers: {
          token: `${readCookie('token')}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if ('fileUrl' in response.data.staff.profile) {
            setImageUrl(response.data.staff.profile.fileUrl);
            window.localStorage.setItem(
              'imageUrl',
              response.data.staff.profile.fileUrl
            );
          }
        });
    } catch {}
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  //Function for logout
  const logout = async () => {
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/auth/logout`, {
      headers: {
        token: `${readCookie('token')}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        window.localStorage.setItem('logged_in', false);
        window.localStorage.removeItem('schoolsId');
        window.localStorage.removeItem('userId');
        window.localStorage.removeItem('name');
        window.localStorage.removeItem('id');
        window.localStorage.setItem('imageUrl', null);
        deleteCookie();

        window.location.href = `${process.env.REACT_APP_REDIRECT}`;
      });
  };

  return (
    <div className='ProfileSidebar'>
      <div className='Above-div'>
        <Avatar alt='Remy Sharp' src={imageUrl} className='Above-div-avatar' />
        <div>{adminName || 'Admin'}</div>
      </div>
      <div className='Lower-div'>
        <div className='Lower-div-above'>
          <div className='menu-Items'>
            <PersonIcon className='menu-Icon'></PersonIcon>
            <Link
              to='/profile/edit'
              style={{ textDecoration: 'none', color: 'black' }}
            >
              {' '}
              <div className='menu-Name'> Edit Profile</div>
            </Link>
          </div>

          <div className='menu-Items'>
            <Link
              to='/profile/password'
              style={{
                display: 'flex',
                textDecoration: 'none',
                color: 'black',
              }}
            >
              <LocalMallOutlinedIcon className='menu-Icon'></LocalMallOutlinedIcon>
              <div className='menu-Name'> Password</div>
            </Link>
          </div>
          <div className='menu-Items'>
            <Link
              to='/profile/registration-info'
              style={{
                display: 'flex',
                textDecoration: 'none',
                color: 'black',
              }}
            >
              <LibraryBooksOutlinedIcon className='menu-Icon'></LibraryBooksOutlinedIcon>
              <div className='menu-Name'> Registration Info</div>
            </Link>
          </div>
        </div>
        <div
          className='Lower-div-Lower'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            logout();
          }}
        >
          <div className='Lower-div-Lower-Icon'>
            <ExitToAppIcon></ExitToAppIcon>
          </div>
          <div className='logout'>Logout</div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSidebar;
