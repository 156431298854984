import React from 'react';
import { Link } from 'react-router-dom';
import user from '../user-circle.svg';

const ProfileImage = () => {
  const imageUrl = window.localStorage.getItem('imageUrl');
  const image =
    imageUrl !== 'null' && imageUrl !== 'undefined' ? imageUrl : user;
  return (
    <Link to='/profile/edit'>
      <img src={image} alt='profile' />
    </Link>
  );
};

export default ProfileImage;
