import React, { useState, useEffect } from 'react';
import Switch from 'react-input-switch';
import { toast } from 'react-toastify';
const { readCookie } = require('../Functions/Cookies.js');

// import React, { useState, useEffect } from 'react'
// import Switch from 'react-input-switch'
// function Myswitch({ enabled }) {

//     const [value, setValue] = useState('yes');

//     useEffect(() => {
//         if (enabled == true) {
//             setValue("yes")
//         }
//         else {
//             setValue("no")
//         }
//     }, [])

//     console.log(value)
//     return (<Switch on="yes" off="no" value={value} onChange={setValue} />)
// }

// export default Myswitch

function Myswitch({ enabled, type, id }) {
  const [value, setValue] = useState('yes');

  useEffect(() => {
    enabled ? setValue('yes') : setValue('no');
  }, []);

  const change = async () => {
    console.log('Enable change request going to be made');

    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/${type}/${id}/enable`, {
      method: 'PATCH',
      headers: {
        token: `${readCookie('token')}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('Response for enable patch request:');
        console.log(response);
        if (response.status === 'success') {
          enabled = !enabled;
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      });
  };

  console.log('Value:', value);
  return (
    <Switch
      on='yes'
      off='no'
      value={value}
      onChange={() => {
        console.log('Enable button clicked');
        value == 'no' ? setValue('yes') : setValue('no');
        change();
      }}
    />
  );
}

export default Myswitch;
