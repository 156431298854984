import React, { useState, useEffect } from 'react';
import './sidebar.css';
import Avatar from '@material-ui/core/Avatar';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { Link } from 'react-router-dom';
import LaptopMacOutlinedIcon from '@material-ui/icons/LaptopMacOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
const { readCookie, deleteCookie } = require('../Functions/Cookies.js');

function Sidebar() {
  const [adminName, setAdminName] = useState('Admin');
  const [imageUrl, setImageUrl] = useState(null);

  //Function used to get the image on sidebar
  const getProfileDetails = async () => {
    const userId = window.localStorage.getItem('userId');
    try {
      await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}`, {
        headers: {
          token: `${readCookie('token')}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setAdminName(response.data.staff.name);
          setImageUrl(response.data.staff.profile.fileUrl);
          window.localStorage.setItem('imageUrl', imageUrl);
        });
    } catch {}
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  //Function for logout
  const logout = async () => {
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/auth/logout`, {
      headers: {
        token: `${readCookie('token')}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        window.localStorage.setItem('logged_in', false);
        window.localStorage.removeItem('schoolsId');
        window.localStorage.removeItem('userId');
        window.localStorage.removeItem('name');
        window.localStorage.removeItem('id');
        window.localStorage.setItem('imageUrl', null);
        deleteCookie();

        window.location.href = `${process.env.REACT_APP_REDIRECT}`;
      });
  };

  return (
    <div className='Sidebar'>
      <div className='Above-div'>
        <Avatar
          alt='Remy Sharp'
          src={imageUrl || ''}
          className='Above-div-avatar'
        />
        <div>{adminName}</div>
      </div>
      <div className='Lower-div'>
        <div className='Lower-div-above'>
          <div className='menu-Items'>
            <HomeIcon className='menu-Icon'></HomeIcon>
            <Link to='/' style={{ textDecoration: 'none', color: 'black' }}>
              {' '}
              <div className='menu-Name'> Dashboard</div>
            </Link>
          </div>
          <div className='menu-Items allMembers'>
            <GroupOutlinedIcon className='menu-Icon'></GroupOutlinedIcon>
            <div className='menu-Name'>
              <Link to='/teacher' className='menu-Items-link'>
                All Members
                <span className='allMemberIcon'>
                  <ChevronRightOutlinedIcon></ChevronRightOutlinedIcon>
                </span>
              </Link>
              <div className='drop-Down'>
                <div>
                  <Link
                    to='/teacher'
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <div className='link'>Teacher</div>
                  </Link>
                </div>
                <hr
                  style={{
                    marginBlockStart: '0px',
                    marginBlockEnd: '0px',
                    opacity: '0.3',
                  }}
                />
                <div>
                  <Link
                    to='/student'
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <div className='link'>Students </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className='menu-Items'>
            <Link
              to='/grade'
              style={{
                display: 'flex',
                textDecoration: 'none',
                color: 'black',
              }}
            >
              <LaptopMacOutlinedIcon className='menu-Icon'></LaptopMacOutlinedIcon>
              <div className='menu-Name'> Grades</div>
            </Link>
          </div>
          <div className='menu-Items'>
            <Link
              to='/role'
              style={{
                display: 'flex',
                textDecoration: 'none',
                color: 'black',
              }}
            >
              <PersonOutlineOutlinedIcon className='menu-Icon'></PersonOutlineOutlinedIcon>
              <div className='menu-Name'> Role Mapping</div>
            </Link>
          </div>
        </div>
        <div className='Lower-div-Lower' style={{ cursor: 'pointer' }}>
          <div className='Lower-div-Lower-Icon' onClick={logout}>
            <ExitToAppIcon></ExitToAppIcon>
          </div>
          <div className='logout' onClick={logout}>
            Logout
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
